import React, { useState } from "react";
import { useSelector } from "react-redux";
import { projetService } from "../../../services/projet-service";
import { ApplicationState } from "../../../store";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { SubmitButton } from "../../generic/submit-button";
import { ProjetPiece, TypePieceValidation } from "../../../model/entities/projet-piece";
import {BodyValidationDebouclage} from "../../../model/dto/body/validation-debouclage-body"
import { LoadingSpinner } from "../../generic/loading-spinner";
import { FormErrorHeader } from "../../generic/form-error-header";
import {FileUpload} from "../../generic/file-upload";
import dayjs from "dayjs";
import DateMaskFieldNew from "../../generic/date-mask-field";
import { EcheancierProjet } from "../../../model/entities/echeancier-projet";
import { Form } from "semantic-ui-react";
import { InputField } from "../../generic/input-field";

type Props = {
  projetId?: number;
  echeanciersProjet?: EcheancierProjet[];
  isPageDon?:boolean;
  onActionSuccess: (response: Response) => void;
  onActionFailure?: () => void;
};

type FieldData = {
  value?: any;
  name?: string;
  isValid?: boolean;
};

type FormData = {
  userPieceList : ProjetPiece[],
  dateSignature : FieldData,
  iban: FieldData
}


export function ValidateDebouclageForm({
  projetId,
  echeanciersProjet,
  isPageDon,
  onActionFailure,
  onActionSuccess,
}: Props) {
  const [form, setForm] = useState<FormData>({
    userPieceList : [],
    dateSignature : {name: 'dateSignature', value : '', isValid: true},
    iban : {name: 'iban', value : '', isValid: true},
  })
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormError, setIsFormError] = useState(false);
  const [isDateError, setIsDateError] = useState<boolean>(false)
  const [dateErrorMessage, setDateErrorMessage] = useState<string>('')

  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

  const onFormNotValid = () => {
    setIsFormError(true)
  }


  const validateForm = () => {
    const hasContratDePret = form.userPieceList.some((piece) => piece.TypePiece === TypePieceValidation.CONTRAT_DE_PRET);
    const isDateValid = validateDate(form.dateSignature.value);

    if (hasEcheance) {
      return hasContratDePret && isDateValid && validateMandatory(form.iban.value);
    } else {
      const hasMandatSepa = form.userPieceList.some((piece) => piece.TypePiece === TypePieceValidation.MANDAT_SEPA);
      return hasContratDePret && hasMandatSepa && isDateValid;
    }
  };

  const onChangePiece = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    if (element.files) {
      const userPiece: ProjetPiece = {
        TypePiece: element.name,
        Document: element.files[0],
        projetId: projetId,
      };

      const updatedList = form.userPieceList.filter((piece) => piece.TypePiece !== element.name);
      setForm({...form, userPieceList: [...updatedList, userPiece]});
    }

    if (isFormSubmitted) {
      validateForm();
    }
  }

  const handleDateChange = (
    date: Date | null,
    field: string,
    isValid?: boolean
  ): void => {
    if(date){
      setForm( {...form ,  dateSignature : {name: 'dateSignature', value : date, isValid: isValid} });
    }
    if (isFormSubmitted) {
      validateForm();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string): void => {
    const fieldValue: string = e.target.value;
    const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName } };
  
    setForm({ ...form,  ...newField});
    setIsFormError(false)
}

  const wrongDateMessage = isPageDon ? "Merci de remplir la date de signature du contrat de don" : "Merci de remplir la date de signature du contrat de prêt";

  const validateDate = (date: Date) => {
    if(!date){
      setIsDateError(true)
      setDateErrorMessage(wrongDateMessage)
      return false
    } 
    setIsDateError(false) 
    return true        
  }

  const validateMandatory = (value: any): boolean => {
    return value && value !== "";
  };

  const hasEcheance = echeanciersProjet ? echeanciersProjet.some(echeance => echeance.Annuite! >= 500000) : false;

  const body : BodyValidationDebouclage = {
    userPieceList : form.userPieceList,
    DateSignature : dayjs(form.dateSignature.value).format("DD/MM/YYYY"),
    Iban : form.iban.value,
    IsDon : isPageDon 
  }

  return (
    <div>
      <LoadingSpinner>
        <Form>
          <FormErrorHeader
            message="Veuillez transmettre les deux pièces justificatives et la date"
            displayed={isFormError}
          />
          <Form.Field width={16}>
            <FileUpload
              libelle="Contrat de prêt signé par la collectivité"
              name={TypePieceValidation.CONTRAT_DE_PRET}
              isLimited={false}
              onChange={onChangePiece}
            />
          </Form.Field>
          <Form.Field width={16}>
            <DateMaskFieldNew
              data={form.dateSignature}
              label="Date de signature du contrat de prêt par la collectivité"
              className="field"
              submitted={isFormSubmitted}
              onChange={(date: Date | null, isValid?: boolean) => {
                handleDateChange(date, "dateSignatureContratPret", isValid);
              }}
              error={dateErrorMessage}
              onError={isDateError}
              validateField={validateMandatory}
              formatModel="# # / # # / # # # #"
              mask="_"
              type="tel"
              allowEmptyFormatting
            />
          </Form.Field>
          {!hasEcheance && (
            <Form.Field width={16}>
              <FileUpload
                libelle="Mandat SEPA signé par le comptable public"
                name={TypePieceValidation.MANDAT_SEPA}
                isLimited={false}
                onChange={onChangePiece}
              />
            </Form.Field>
          )}
          {hasEcheance && (
            <Form.Field width={16}>
              <InputField 
                data={form.iban}
                label="IBAN pour le retrait des fonds"
                submitted={isFormSubmitted}
                error="Merci de renseigner un IBAN"
                onChange={(e) => handleInputChange(e, form.iban.name)}
                validateField={validateMandatory}
              />
            </Form.Field>
          )}
          <div className="button-bar" css={{ textAlign: "center" }}>
            <SubmitButton
              data={form.userPieceList}
              action={() => projetService.validateVillyzDebouclagePieceJustificative(projetId, loginProps?.oauth, body)}
              label="Enregistrer"
              onPreSubmit={() => setIsFormSubmitted(true)}
              onActionSuccess={onActionSuccess}
              onActionFailure={onActionFailure}
              validateForm={validateForm}
              onFormNotValid={onFormNotValid}
            />
          </div>
        </Form>
      </LoadingSpinner>
    </div>
  );
}
