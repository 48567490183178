export interface TypeCollecte {
    Code: string,
    Libelle: string
}


const EMPRUNT_CITOYEN: string = "EMC";
const DON: string = "DON";
const COMBINAISON_EMPRUNT_DON: string = "CED";

export const TypeCollecte = {
    EMPRUNT_CITOYEN,
    DON,
    COMBINAISON_EMPRUNT_DON
}
