import React, { useEffect, useState } from "react";
import { Container, Tab } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { InvestissementHistoryResponse, InvestissementHistoryResponseListResponse } from "../../../model/dto/response";
import { investmentService } from "../../../services/investment-service";
import { InvestissementsPane } from "./tab/investissements-pane";
import { EcheancesPane } from "./tab/echeances-pane";
import { trackPromise } from "react-promise-tracker";
import { Profil } from "../../../model/entities/profil";

type Props = {}

export function DashboardCollectivite({}: Props) {

  const [isLoading, setIsLoading] = useState(false)
  const [histoCollecte, setHistoCollecte] = useState<InvestissementHistoryResponse[]>()

  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

  useEffect(() => {
    if(loginProps?.oauth?.profilCode === Profil.PORTEUR_PROJET) {
      setIsLoading(true)
      trackPromise(investmentService.getInvestmentCollectiviteHistory(loginProps?.oauth?.etablissementId, loginProps?.oauth)
      .then((container: InvestissementHistoryResponseListResponse) => {
        setHistoCollecte(container.InvestissementHistoryResponseList!)
        setIsLoading(false)
      }));
    }
  }, []);

  const panes = [
    {
      menuItem: 'Mes projets',
      render: () => {
        return (
          <Tab.Pane attached={false} css={{
            background: 'transparent !important',
            border: 'none !important'
          }}>
            <InvestissementsPane 
              histoInvestissement={histoCollecte}
            />
          </Tab.Pane>
        )
      },
    },
    {
      menuItem: 'Mes échéances',
      render: () => {
        return (
          <Tab.Pane attached={false} css={{
            background: 'transparent !important',
            border: 'none !important'
          }}>
            <EcheancesPane />
          </Tab.Pane>
        )
      },
    },
  ]

  const Tabs = () => (
    <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
  )

  return (
    <Container>
      <Tabs />
    </Container>
  );
}
