import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { OnboardingStatus } from "../../model/entities/onboarding-status";
import { Profil } from "../../model/entities/profil";
import { StatutPieceJustificative } from "../../model/entities/statut-piece-justificative";
import { UserPieceJustificative } from "../../model/entities/user-piece-justificative";
import { AuthenticationState } from "../../model/state/authentication-state";
import { ApplicationState } from "../../store";

type Props = {
  status?: OnboardingStatus;
  kycErrorCount?: number
};

export function ProfilCompletionHeader({ status, kycErrorCount }: Props) {
  const loginProps: AuthenticationState = useSelector<
    ApplicationState,
    AuthenticationState
  >((state) => state.authentication);

  const config = [
    {
      code: OnboardingStatus.PARTIELLEMENT_COMPLET,
      profile: Profil.PORTEUR_PROJET,
      description:
        "Vous pouvez commencer à créer vos demandes de financement. Vous devrez soumettre au moins l’identité et l’adresse du représentant, ainsi que l’adresse de l'établissement avant la publications de vos demandes de financement.",
      bgColor: "#fcf8e3",
      textColor: "#8a6d3b",
    },
    {
      code: OnboardingStatus.PRESQUE_COMPLET,
      profile: Profil.PORTEUR_PROJET,
      description:
        "Vous pouvez d’ores et déjà publier vos demandes de financement à Villyz. Vous devrez cependant soumettre vos pièces justificatives avant de pouvoir déboucler les fonds collectés.",
      bgColor: "#fcf8e3",
      textColor: "#8a6d3b",
    },
    {
      code: OnboardingStatus.COMPLET,
      profile: Profil.PORTEUR_PROJET,
      description:
        "Votre profil est complet, il va être validé par nos équipes sous 48h. Vos demandes de financement pourront ensuite être publiées.",
      bgColor: "#d9edf7",
      textColor: "#31708f",
    },
    {
      code: OnboardingStatus.VALIDE,
      profile: Profil.PORTEUR_PROJET,
      description:
        "Votre profil est complet et validé par nos équipes. Vous avez accès à toutes les fonctionnalités de Villyz pour un compte professionnel.",
      bgColor: "#dff0d8",
      textColor: "#3c763d",
    },
    {
      code: OnboardingStatus.ERREUR,
      profile: Profil.PORTEUR_PROJET,
      description:
        "Vous pouvez toujours publier vos projets sur la plateforme. Toutefois, il faut recommencer la validation de votre pièce d’identité.",
      bgColor: "#f2dede",
      textColor: "#a94442",
    },
    {
      code: OnboardingStatus.PARTIELLEMENT_COMPLET,
      profile: Profil.INVESTISSEUR,
      description:
        "Renseignez votre profil en quelques clics pour commencer à investir.",
      bgColor: "#fcf8e3",
      textColor: "#8a6d3b",
    },
    {
      code: OnboardingStatus.PRESQUE_COMPLET,
      profile: Profil.INVESTISSEUR,
      description:
        "Vous pouvez investir, n'oubliez pas cependant de finaliser la validation de votre compte afin de pouvoir effectuer vos futures retraits de fonds.",
      bgColor: "#d9edf7",
      textColor: "#31708f",
    },
    {
      code: OnboardingStatus.COMPLET,
      profile: Profil.INVESTISSEUR,
      description:
        "Vous pouvez commencer à investir.",
      bgColor: "#d9edf7",
      textColor: "#31708f",
    },
    {
      code: OnboardingStatus.VALIDE,
      profile: Profil.INVESTISSEUR,
      description:
        "Votre profil est complet et validé par nos équipes. Vous avez accès à toutes les fonctionnalités de Villyz pour un compte particulier.",
      bgColor: "#dff0d8",
      textColor: "#3c763d",
    },
    {
      code: OnboardingStatus.ERREUR,
      profile: Profil.INVESTISSEUR,
      description:
        "Vous pouvez toujours investir dans les projets présentés sur la plateforme. Toutefois, il faut recommencer la validation de votre pièce d’identité.",
      bgColor: "#f2dede",
      textColor: "#a94442",
    },
    {
      code: OnboardingStatus.VAL_EMAIL_A_FAIRE,
      profile: Profil.INVESTISSEUR,
      description: "Vos pièces justificatives sont validées. Merci de maintenant valider votre adresse mail afin de pouvoir accéder à toutes les fonctionnalités de la plateforme.",
      bgColor: "#fcf8e3",
      textColor: "#8a6d3b",
    },
    {
      code: OnboardingStatus.VAL_EMAIL_A_FAIRE,
      profile: Profil.PORTEUR_PROJET,
      description: "Vos pièces justificatives sont validées. Merci de maintenant valider votre adresse mail afin de pouvoir accéder à toutes les fonctionnalités de la plateforme.",
      bgColor: "#fcf8e3",
      textColor: "#8a6d3b",
    },
  ];

  const activeConfig = useMemo(() => {
    var mappedConfig = config.find(
      (c) =>
        c.code === status?.Code && c.profile === loginProps.oauth?.profilCode
    );

    if(status?.Code === OnboardingStatus.ERREUR && kycErrorCount && kycErrorCount >= 3 && mappedConfig) {
      mappedConfig = {...mappedConfig, description: 'Suite à de trop nombreux échecs dans le traitement de vos documents nous vous invitons à prendre contact avec le support Villyz à l\'adresse contact@villyz.fr afin de valider votre profil'}
    }

    return mappedConfig
  }, [status, kycErrorCount]);

  return (
    <>
      <div
        key={status?.Code}
        css={{
          display: "block",
          background: activeConfig?.bgColor,
          color: activeConfig?.textColor,
          padding: "40px",
          fontSize: "30px",
          borderRadius: "0.28571429rem !important",
          marginBottom: "30px",
          boxShadow: "0px 1px 3px 0px #D4D4D5, 0px 0px 0px 1px #D4D4D5",
        }}
      >
        {activeConfig?.profile === Profil.INVESTISSEUR && (status?.Code === OnboardingStatus.COMPLET || status?.Code === OnboardingStatus.PRESQUE_COMPLET) ? 
          (<div css={{fontWeight: 'bold'}}>C’est parti!</div>) 
          : 
          (
            <>
              {status?.Code === OnboardingStatus.ERREUR ? (
                <div css={{fontWeight: 'bold', color: '#a94442' }}>Pièce d’identité à valider</div>
              ) : (
                <>
                  Statut du profil :
                  <div
                    css={{
                      display: "inline-block",
                      fontWeight: "bold",
                      marginLeft: "0.5rem",
                    }}
                  >
                    {status?.Libelle}
                  </div>
                </>
              )}
            </>
          )}
        <div
          css={{
            display: "block",
            fontSize: "16px",
            marginTop: "10px",
          }}
        >
          {activeConfig?.description}
        </div>
      </div>
    </>
  );
}  