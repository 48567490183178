import { addDays, parseISO } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Header, Icon, Modal, Popup, Progress } from "semantic-ui-react";
import { DefaultResponse, DepartementListResponse, InvestResponse, ProfilCode} from "../../../../model/dto/response";
import Projet from "../../../../model/projet";
import { projetService } from "../../../../services/projet-service";
import { ProjectInfos, References } from "../project-detail";
import { AuthenticationState } from "../../../../model/state/authentication-state"
import { ApplicationState } from "../../../../store"
import { useSelector } from "react-redux";
import { StatutProjet } from "../../../../model/entities/statut-projet";
import { AlerterUserBody } from "../../../../model/dto/body/alerter-user-body";
import { SubmitButton } from "../../../generic/submit-button";
import { DemandeDebouclageForm } from "../../forms/demande-debouclage-form";
import { ValidateDebouclageForm } from "../../forms/validate-debouclage-form";
import { FinalisationForm } from "../../forms/finalisation-form";
import { useNavigate } from "react-router-dom";
import { OnboardingStatus } from "../../../../model/entities/onboarding-status";
import { AlerterUserRepriseBody } from "../../../../model/dto/body/alerter-user-reprise-body";
import { CategorieEtablissement } from "../../../../model/entities/categorie-etablissement";
import { TypeEmprunt } from "../../../../model/entities/type-emprunt";
import { referenceService } from "../../../../services/reference-service";
import { Departement } from "../../../../model/entities/departement";
import { ForceEcheanceForm } from "../../forms/force-echeance-form";
import { ProlongationForm } from "../../forms/prolongation-form";
import { investmentService } from "../../../../services/investment-service";
import { EcheancierProjet } from "../../../../model/entities/echeancier-projet";
import { ProcessProjectToAVenirStatutBody } from "../../../../model/dto/body/process-to-avenir-statut-body";
import { PublishBody } from "../../../../model/dto/body/publish-body";
import { TypeCollecte } from "../../../../model/entities/type-collecte";
import { LinkButton } from "../../../generic/link-button";

type Props = {
  project: Projet;
  projetInfo: ProjectInfos;
  profile: ProfilCode;
  isUserAlerted?: boolean;
  score?: number;
  reponseNumeroList?: number[];
  setIsUserAlerted: (isUserAlerted: boolean) => void;
  nombreInvestisseur: number;
  categorieEtablissementCode?: string;
  onPublishSuccess: (response: Response) => void;
  endDate: Date;
  reload: () => void;
  references: References;
};

export default function FinancementParticipatif({
    project,
    projetInfo,
    profile,
    isUserAlerted,
    score,
    reponseNumeroList,
    setIsUserAlerted,
    nombreInvestisseur,
    categorieEtablissementCode,
    onPublishSuccess,
    endDate,
    reload,
    references
}: Props) {

    const navigate = useNavigate()
    const [isEdited, setIsEdited] = useState(false)
    const [secondOpen, setSecondOpen] = useState(false)
    const [secondPopupMessage, setSecondPopupMessage] = useState('Cliquez ici pour être alerté ou ne plus être alerté du lancement de la levée!')
    const [isNotSameHorizon, setIsNotSameHorizon] = useState(false)
    const [thirdOpen, setThirdOpen] = useState(false)
    const [suspendedOpen, setSuspendedOpen] = useState(false)
    const [suspendedPopupMessage, setSuspendedPopupMessage] = useState('Cliquez ici pour être alerté ou ne plus être alerté de la reprise de la levée!')
    const [departementReferenceList, setDepartementReferenceList] = useState<Departement[]>([])
    const [isModalNextEcheanceOpen, setIsModalNextEcheanceOpen] = useState(false)
    const [echeanciersProjet, setEcheanciersProjet] = useState<EcheancierProjet[]>([])

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    useEffect(()=>{
        if(loginProps.isAuthenticated && profile === ProfilCode.INVESTISSEUR) {
            referenceService.getAllDepartementList(loginProps?.oauth)
            .then(response => {
                return response.json() as Promise<DepartementListResponse>
            })
            .then(response => {
                setDepartementReferenceList(response.DepartementList!)
            })
        }
        if(project && profile === ProfilCode.VILLYZ) {
            investmentService.getEcheanciersProjetByProjetId(project.Id!, loginProps?.oauth)
            .then(response => setEcheanciersProjet(response.EcheanciersProjet!))
        }
    }, [])

    // On récupère la liste des codes des départements restreints
    const displayRestrictedDepartementsCode = useMemo(() => {
    let toReturn = ""
    if(projetInfo.restrictedDepartements) {
        for(let i = 0; i < projetInfo.restrictedDepartements.length; i++) {
            if(i !== 0) {
                toReturn += ", "
            }
            toReturn += departementReferenceList.find(dep => dep.Id === projetInfo.restrictedDepartements![i].DepartementId)?.Designation + 
            "(" + departementReferenceList.find(dep => dep.Id === projetInfo.restrictedDepartements![i].DepartementId)?.Code + ")"
        }
    }
    return toReturn
    }, [departementReferenceList]);

    // On vérifie si le citoyen peut investir
    const isNotRestricted = useMemo(() => {
        if(!loginProps.oauth || !project.IsRestrictedZoneGeo) {
            return true
        }

        if(!loginProps.user?.Etablissement === undefined) {
            if(projetInfo.restrictedDepartements && projetInfo.restrictedDepartements.length > 0 ) {
                for(let i = 0; i < projetInfo.restrictedDepartements.length; i++) {
                    if(loginProps.user?.DepartementId === projetInfo.restrictedDepartements[i].DepartementId) {
                        return true
                    } 
                }
                return false
            } 
            return true 
        }
        else if(loginProps.user?.Etablissement !== undefined) {
            if(projetInfo.restrictedDepartements && projetInfo.restrictedDepartements.length > 0 ) {
                for(let i = 0; i < projetInfo.restrictedDepartements.length; i++) {
                    if(loginProps.user?.Etablissement?.DepartementId === projetInfo.restrictedDepartements[i].DepartementId) {
                        return true
                    } 
                }
                return false
            } 
            return true 
        }
    }, []);

    const statutTermines = [
        StatutProjet.CLOTURE, 
        StatutProjet.DEBOUCLAGE_DEMANDE, 
        StatutProjet.DEBOUCLAGE_POSSIBLE,
        StatutProjet.DEBOUCLAGE_VALIDE,
        StatutProjet.DEBOUCLAGE_FINALISE,
        StatutProjet.FINANCE
    ]

    const statutPrePublies = [
        StatutProjet.INCOMPLET,
        StatutProjet.COMPLET,
        StatutProjet.EN_TRAITEMENT,
        StatutProjet.A_VENIR,
        StatutProjet.BIENTOT_DISPONIBLE,
        StatutProjet.INFORMATION_MANQUANTE,
        StatutProjet.ANNULE,
        StatutProjet.REFUSE
    ]

    const statutsClotures = [
        StatutProjet.CLOTURE, 
        StatutProjet.DEBOUCLAGE_DEMANDE,
        StatutProjet.DEBOUCLAGE_POSSIBLE,
        StatutProjet.DEBOUCLAGE_VALIDE,
        StatutProjet.DEBOUCLAGE_FINALISE
    ]

    const TypeEmpruntLibelle = [
        {
            Code: TypeEmprunt.PRET,
            Libelle: "Prêt"
        },
        {
            Code: TypeEmprunt.TITRES,
            Libelle: "Titres participatifs"
        },
        {
            Code: TypeEmprunt.OBLIGATIONS,
            Libelle: "Obligations"
        }
    ]

    useEffect(() => {
        const reponseCinq: boolean = reponseNumeroList?.find(reponseNumero => reponseNumero === 5) !== undefined;
        const reponseSix: boolean = reponseNumeroList?.find(reponseNumero => reponseNumero === 6) !== undefined;
        const reponseSept: boolean = reponseNumeroList?.find(reponseNumero => reponseNumero === 7) !== undefined;
       
        if(reponseSept || reponseSix && project.MaturiteCreditCitoyen && project.MaturiteCreditCitoyen >= 84 || reponseCinq && project.MaturiteCreditCitoyen && project.MaturiteCreditCitoyen <= 84) {
            setIsNotSameHorizon(false)
        }
        else {
            setIsNotSameHorizon(true)
        }
    }, [reponseNumeroList, project])

    const pourcentageCollecte = useMemo(() => {
        if(project.MontantCagnotteActuel && project.MontantFinancementCitoyenDemande) {
            const pourcentage = project.MontantCagnotteActuel / project.MontantFinancementCitoyenDemande
            return Math.round(pourcentage * 100) / 100
        }
        else {
            return 0
        }
    }, [])

    const displayAvertissement = useMemo(() => {
        return ((score || score === 0)  && ((categorieEtablissementCode === CategorieEtablissement.COLLECTIVITE && score < 60) || (categorieEtablissementCode === CategorieEtablissement.SOCIETE && score < 90))) || isNotSameHorizon
    }, [score, categorieEtablissementCode, isNotSameHorizon])

    const onButtonClick = () => {
        if(displayAvertissement){
            setThirdOpen(true) 
        }
        else{
            setThirdOpen(false)
            navigate(`/projets/${project.Id}/investissement/v2`)
        }
    }
  
    const handleButtonClick = () => {
        if(loginProps.isAuthenticated) {
            const body: AlerterUserBody = {
            IsAlert: !isUserAlerted
            }
            projetService.alerterUser(body, project.Id!, loginProps?.oauth)

            if(!isUserAlerted) {
            setSecondPopupMessage('Vous serez alerté du lancement de la levée!')
            }
            else {
            setSecondPopupMessage('Vous ne serez plus alerté')
            }

            setIsUserAlerted(!isUserAlerted)
        }
        else {
            setSecondOpen(true)
        }
    }

    const handleSuspensionButtonClick = () => {
        if(loginProps.isAuthenticated) {
            const body: AlerterUserRepriseBody = {
            IsAlert: !isUserAlerted
            }
            projetService.alerterUserReprise(body, project.Id!, loginProps?.oauth)

            if(!isUserAlerted) {
            setSuspendedPopupMessage('Vous serez alerté de la reprise de la levée!')
            }
            else {
            setSecondPopupMessage('Vous ne serez plus alerté')
            }

            setIsUserAlerted(!isUserAlerted)
        }
        else {
            setSuspendedOpen(true)
        }
    }

    const isProjetEnTraitementEligible = () => {
        if(
            project.StatutProjetCode === StatutProjet.INCOMPLET || 
            project.StatutProjetCode === StatutProjet.COMPLET
        ) {
            
            return project.Titre !== null && project.DescriptionCourt !== null && project.MontantFinancementCitoyenDemande ? true : false
        } 
    }

    const processProjectToAvenirBody: ProcessProjectToAVenirStatutBody = {
        IsEmprunt: true
    }

    const publishBody : PublishBody = {
        IsEmprunt: true
    }

    
    const statutNotAvailableToSwicth = [
        StatutProjet.INCOMPLET,
        StatutProjet.COMPLET,
        StatutProjet.EN_TRAITEMENT,
        StatutProjet.A_VENIR,
        StatutProjet.INFORMATION_MANQUANTE,
        StatutProjet.ANNULE,
        StatutProjet.REFUSE
    ]

    const isSwitchAvailable = (): boolean => {
        if(loginProps.oauth?.profilCode ===  ProfilCode.PORTEUR_PROJET || loginProps.oauth?.profilCode === ProfilCode.VILLYZ){
          return false
        }
  
        return !statutNotAvailableToSwicth.includes(project.StatutDonProjetCode!)
    }

    return (
        <div css={{height:"100%", display:"flex", flexDirection:"column", justifyContent:"space-evenly"}}>
            <div css={{
                marginTop: '.7em'
            }}>
                <div css={{ textAlign:"center", fontSize: "22px", fontWeight:"bold" }}>
                    {(statutPrePublies.find(statut => statut === project.StatutProjetCode)) && (
                        <>
                        {project.MontantFinancementCitoyenDemande ? project.MontantFinancementCitoyenDemande.toLocaleString("fr-FR") + "€ " : " 0€ "}
                        <span css={{ color:"#7F7F7F" }}>{project.MontantFinancementCitoyenDemande! >= 1 ? "recherchés" : "recherché"}</span>
                        </>
                    )}
                    {(statutTermines.find(statut => statut === project.StatutProjetCode) || project.StatutProjetCode === StatutProjet.PUBLIE) && (
                        <>
                        {project.MontantCagnotteActuel ? (project.MontantCagnotteActuel / 100).toLocaleString("fr-FR") + "€ " : " 0€ "}
                        <span css={{ color:"#7F7F7F" }}>{project.MontantCagnotteActuel! >= 1 ? "collectés" : "collecté"}</span>
                        </>
                    )}
                </div>
                {!statutTermines.find(statut => statut === project.StatutProjetCode) && (
                    <Progress 
                        percent={pourcentageCollecte} 
                        size="small" 
                        color="blue" 
                        css={{
                            marginTop: "10px !important"
                        }}
                    >
                        {!statutPrePublies.find(status => status === project.StatutProjetCode) && (
                            <div css={{ display: "flex", justifyContent: "center"}}>
                                <div css={{fontSize:"14px", color:"#404040"}}>
                                    {project.MontantFinancementCitoyenDemande?.toLocaleString("fr-FR")}€{" "}
                                    <span css={{ color:"#7F7F7F" }}>recherchés</span>
                                </div>
                            </div>
                        )}
                        {project.StatutProjetCode === StatutProjet.BIENTOT_DISPONIBLE && (
                            <div css={{textAlign: "center", marginTop:"10px"}}>
                                    <span css={{ fontSize:"14px", color:"#7F7F7F" }}>Pour ne pas manquer l'opportunité :</span>
                            </div>
                        )}
                    </Progress>
                )}
                {statutTermines.find(statut => statut === project.StatutProjetCode) && (
                    <Progress percent={100} size="small" color="blue" css={{marginTop:"10px !important"}}>
                        {project.StatutProjetCode === StatutProjet.FINANCE &&
                        <div css={{display: "flex", justifyContent: "center"}}>
                            <div css={{fontSize:"14px", color:"#404040"}}>
                                    {nombreInvestisseur || ""}
                                    <span css={{ color:"#7F7F7F" }}>{nombreInvestisseur > 1 ? " prêteurs" : nombreInvestisseur > 0 ? " prêteur" : "aucun prêteur"}</span>
                            </div>
                        </div>
                        }
                    </Progress>
                )}
            </div>
            <div 
                css={{
                    marginTop: '1em',
                    marginBottom: '1em'
                }}
            >
                {profile === ProfilCode.PORTEUR_PROJET && isProjetEnTraitementEligible() && (
                <SubmitButton 
                    label="Publier à Villyz"
                    action={() => projetService.publishProjet(project.Id!, publishBody, loginProps?.oauth)}
                    onActionSuccess={onPublishSuccess}
                    style={{
                        padding: '10px 20px',
                        width: '100%',
                        fontSize: '16px',
                        fontWeight: 600
                    }}
                />
                )}
                {profile === ProfilCode.VILLYZ && project.StatutProjetCode === StatutProjet.EN_TRAITEMENT && (
                <SubmitButton 
                    label="Rendre public"
                    action={() => projetService.ProcessProjectToAVenirStatut(project.Id!,  processProjectToAvenirBody, loginProps?.oauth)}
                    onActionSuccess={onPublishSuccess}
                    style={{
                        padding: '10px 20px',
                        width: '100%',
                        fontSize: '16px',
                        fontWeight: 600
                    }}
                />
                )}
                {profile === ProfilCode.PORTEUR_PROJET && project.StatutProjetCode === StatutProjet.PUBLIE && endDate && addDays(endDate, -5) <= new Date() && (
                <>
                    <Button 
                    size="big" 
                    color="blue" 
                    fluid
                    onClick={() => setIsEdited(true)}
                    >
                    Prolonger la cagnotte
                    </Button>
                    <Modal onClose={() => setIsEdited(false)} open={isEdited}>
                    <Modal.Header>Prolonger la cagnotte</Modal.Header>
                    <Modal.Content>
                        <ProlongationForm 
                        projet={project}
                        onSubmitSuccess={() => {
                            setIsEdited(false); 
                            reload();
                        }}
                        />
                    </Modal.Content>
                    </Modal>
                </>
                )}
                {profile === ProfilCode.PORTEUR_PROJET && project.StatutProjetCode === StatutProjet.DEBOUCLAGE_POSSIBLE && endDate && (
                <>
                    <Button 
                    size="big" 
                    color="blue" 
                    fluid
                    onClick={() => setIsEdited(true)}
                    >
                    Demander le débouclage
                    </Button>
                    <Modal onClose={() => setIsEdited(false)} open={isEdited}>
                    <Modal.Header>Demande de débouclage</Modal.Header>
                    <Modal.Content>
                        <DemandeDebouclageForm 
                        projet={project}
                        onSubmitSuccess={(response: DefaultResponse) => {
                            setIsEdited(false);
                            reload();
                        }}
                        isDon={false}
                        />
                    </Modal.Content>
                    </Modal>
                </>
                )}
                {profile === ProfilCode.VILLYZ && project.StatutProjetCode === StatutProjet.DEBOUCLAGE_DEMANDE && (
                <>
                <Button
                    size="big"
                    color="blue" 
                    fluid
                    onClick={() => setIsEdited(true)}
                    >
                    Valider le débouclage
                </Button>
                <Modal onClose={() => setIsEdited(false)} open={isEdited}>
                    <Modal.Header>Validation du débouclage</Modal.Header>
                    <Modal.Content>
                        <ValidateDebouclageForm
                            projetId = {project.Id}
                            onActionSuccess = { () => { reload() }}
                            onActionFailure = { () => { reload() }}
                            echeanciersProjet = {echeanciersProjet}
                            isPageDon={false}
                        />
                    </Modal.Content>
                </Modal>
                </>
                )}
                {/* Condition a rajouter pour le cas ou ils veulent le faire deux fois */}
                {profile === ProfilCode.PORTEUR_PROJET && project.StatutProjetCode === StatutProjet.DEBOUCLAGE_VALIDE && !project.ProjectIsPayout && (
                <>
                <Button
                    size="big"
                    color="blue" 
                    fluid
                    onClick={() => setIsEdited(true)}
                    >
                    Finaliser le débouclage
                </Button>
                <Modal onClose={() => setIsEdited(false)} open={isEdited}>
                    <Modal.Header>Finalisation de la demande de débouclage​</Modal.Header>
                    <Modal.Content>
                        <FinalisationForm
                        projet = {project}
                        onSubmitSuccess = { (response: InvestResponse ) => {
                            setIsEdited(false);
                            if(response.IsTraitementOk) {
                            window.location.href = response.RedirectURL!
                            }
                        }}
                        />
                    </Modal.Content>
                </Modal>
                </>
                )}
                {profile === ProfilCode.VILLYZ && project.StatutProjetCode === StatutProjet.FINANCE && (
                <>
                <Button
                    size="big"
                    color="blue" 
                    fluid
                    onClick={() => setIsModalNextEcheanceOpen(true)}
                    >
                    Forcer prochaine échéance
                </Button>
                <Modal onClose={() => setIsModalNextEcheanceOpen(false)} open={isModalNextEcheanceOpen}>
                    <Modal.Header>Déclenchement de la prochaine échéance</Modal.Header>
                    <Modal.Content>
                        <ForceEcheanceForm 
                            projetId={project.Id}
                        />
                    </Modal.Content>
                </Modal>
                </>
                )}
                {profile === ProfilCode.INVESTISSEUR && 
                !loginProps.user?.Etablissement &&
                loginProps.user?.IsMailValid && project.StatutProjetCode === StatutProjet.PUBLIE && 
                isNotRestricted && (
                    <div>
                        <Button 
                            size="big" 
                            color="blue" 
                            fluid
                            onClick={onButtonClick}
                        >
                            Investir
                        </Button>
                        <Modal
                            open={thirdOpen}
                            onClose={() => setThirdOpen(false)}
                        >
                            <Modal.Header>Avertissement</Modal.Header>
                            <Modal.Content>
                                <Modal.Description css={{textAlign: "center", fontSize:"1.25rem"}}>
                                    Un investissement dans un projet de financement participatif présente des risques de perte totale du capital.
                                    Pour avoir plus d’information sur notre méthode de sélection des projets, vous pouvez consultez notre page dédiée aux&nbsp;
                                    <a href="/risques" target="_blank">risques</a>
                                </Modal.Description>
                                <div css={{display: "flex", justifyContent:"center", marginTop:"15px"}}>
                                        <SubmitButton
                                        label="J'ai compris"
                                        onClick={() => navigate(`/projets/${project.Id}/investissement/v2`)}
                                        style={{height: "40px", width: "150px", fontSize:"1.1rem"}}
                                    />
                                </div>
                            </Modal.Content>
                        </Modal>
                    </div>
                )}
                {profile === ProfilCode.INVESTISSEUR && project.StatutProjetCode === StatutProjet.BIENTOT_DISPONIBLE && (
                    <div onClick={handleButtonClick}>
                    <Modal
                    open={secondOpen}
                    onClose={() => setSecondOpen(false)}
                    closeOnDocumentClick
                    trigger={
                        <Popup 
                        content={secondPopupMessage}
                        position='bottom center'
                        size='mini'
                        on='hover'
                        pinned
                        mouseEnterDelay={200}
                        mouseLeaveDelay={200}
                        hideOnScroll
                        trigger={
                            <div>          
                                <Button size="big" 
                                        color="blue" 
                                        fluid
                                >
                                    <Icon css={{color:"white !important"}} size="large" name={isUserAlerted ? "bell" : "bell outline"}/>
                                    <span css={{verticalAlign:"middle"}}>Être alerté du lancement</span>
                                </Button>
                            </div>
                        }
                        />    
                    }
                    >
                        <Header content='Être alerté du lancement de la levee' />
                        <Modal.Content>
                            <p>
                                Merci de vous <a href="/login"> connecter </a> ou vous <a href="/login">inscrire</a> pour être alerté le jour du l'ouverture de la levée !
                            </p>
                        </Modal.Content>
                    </Modal>
                </div>
            )}

            {profile === ProfilCode.INVESTISSEUR && project.StatutProjetCode === StatutProjet.SUSPENDED && (
                <div onClick={handleSuspensionButtonClick}>
                    <Modal
                    open={suspendedOpen}
                    onClose={() => setSuspendedOpen(false)}
                    closeOnDocumentClick
                    trigger={
                        <Popup 
                        content={suspendedPopupMessage}
                        position='bottom center'
                        size='mini'
                        on='hover'
                        pinned
                        mouseEnterDelay={200}
                        mouseLeaveDelay={200}
                        hideOnScroll
                        trigger={
                            <div>          
                                <Button size="big" 
                                        color="blue" 
                                        fluid
                                >
                                    <Icon css={{color:"white !important"}} size="large" name={isUserAlerted ? "bell" : "bell outline"}/>
                                    <span css={{verticalAlign:"middle"}}>Etre alerté de la reprise</span>
                                </Button>
                            </div>
                        }
                        />    
                    }
                    >
                        <Header content='Etre alerté de la reprise de la levée' />
                        <Modal.Content>
                            <p>
                                Merci de vous <a href="/login"> connecter </a> ou vous <a href="/login">inscrire</a> pour être alerté le jour de la reprise de la levée !
                            </p>
                        </Modal.Content>
                    </Modal>
                </div>
            )}

            {loginProps.isAuthenticated 
            && loginProps.oauth?.profilCode === ProfilCode.INVESTISSEUR
            && (loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.COMPLET || loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.VAL_EMAIL_A_FAIRE)
            && !loginProps.user?.IsMailValid
            && project.StatutProjetCode === StatutProjet.PUBLIE 
            && isNotRestricted 
            && (
                <div
                    css={{
                        fontSize: "1.2rem",
                        fontWeight:"bold",
                        textAlign: "center",
                        color: "#085c74"
                    }}
                >
                    <Button 
                        size="big" 
                        color="blue" 
                        fluid
                        onClick={() => navigate(`/profil`)}
                    >
                        Valider mon adresse mail
                    </Button>
                </div>
            )}

            {profile === ProfilCode.INVESTISSEUR && 
            (loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.PRESQUE_COMPLET ||
            loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.ERREUR ||
            loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.COMPLET ||
            loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.VAL_EMAIL_A_FAIRE) && 
            !loginProps.user?.IsMailValid && 
                <Button 
                    size="big" 
                    color="blue" 
                    fluid
                    onClick={reload}
                >
                    J’ai validé mon adresse mail
                </Button>
            }

            {loginProps.isAuthenticated 
            && loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.COMPLET
            && loginProps.user?.IsMailValid
            && project.StatutProjetCode === StatutProjet.PUBLIE 
            && isNotRestricted 
            && (
                <div
                    css={{
                        fontSize: "1.2rem",
                        fontWeight:"bold",
                        textAlign: "center",
                        color: "#085c74"
                    }}
                >
                    Plus que quelques instants avant de pouvoir investir
                </div>
            )}

            {loginProps.oauth?.userId && profile === ProfilCode.INVESTISSEUR && project.StatutProjetCode === StatutProjet.PUBLIE && !isNotRestricted &&
                <div
                    css={{
                        fontSize: "1.2rem",
                        fontWeight:"bold",
                        textAlign: "center",
                        color: "#085c74"
                    }}
                >
                    Cette collecte est limitée à la zone suivante : {displayRestrictedDepartementsCode}
                </div>
            }

            {(project.StatutProjetCode === StatutProjet.FINANCE || (statutsClotures.find(status => status === project.StatutProjetCode))) && (
                <div
                    css={{
                        fontSize: "1.2rem",
                        fontWeight:"bold",
                        textAlign: "center",
                        color: "#085c74"
                    }}
                >
                    Cette collecte est terminée ! L'objectif a été atteint en {Math.floor((endDate.getTime() - parseISO(project.DateDebutFinancementCitoyen!).getTime()) / (1000 * 3600 * 24)) + 1} jours
                </div>
            )}
                {!loginProps.oauth && project.StatutProjetCode === StatutProjet.PUBLIE && isNotRestricted && (
                    <>
                        <Button 
                            size="big" 
                            color="blue" 
                            fluid
                            onClick={() => navigate(`/connexion`)}
                        >
                            Investir
                        </Button>
                    </>
                )}
            </div>
            {project.TypeCollecteCode === TypeCollecte.COMBINAISON_EMPRUNT_DON && 
            isSwitchAvailable() && 
                <div style={{display: 'flex', justifyContent:'center', paddingBottom:10}}>
                    <LinkButton 
                            label="Je représente une société"
                            onlyBlack={true}
                            onClick={() => navigate(`/projets/${project.UrlSlug}/don`)}
                        />
                </div>
            }
            <div css={{
                    display:"flex", 
                    flexDirection:"row", 
                    justifyContent:"start", 
                    alignItems:"center", 
                    fontSize: ".9em",
                    marginBottom: ".7em"
                }}
            >
                <div css={{
                    width:"50%", 
                    display:"flex", 
                    flexDirection:"column"
                    }}
                >
                    <span css={{ 
                            fontWeight: "bold"
                        }}
                    >
                        <Icon name="money bill alternate" size="large" />
                        {project.TypeEmpruntCode === TypeEmprunt.TITRES ? "Taux fixe annuel" : "Taux d'intérêt"}
                    </span>
                    <span>{project.TauxInteretAnnuel}% par an</span>
                </div>
                <div css={{
                        width:"50%", 
                        display:"flex", 
                        flexDirection:"column"
                    }}
                >
                        <span css={{ 
                                fontWeight: "bold"
                            }}
                        >
                            <Icon name="calendar check" size="large" />
                            {project.TypeEmpruntCode === TypeEmprunt.TITRES ? "Période sans amortissement" : "Remboursement"}
                        </span>
                        <span>
                            {
                                project.TypeEmpruntCode === TypeEmprunt.TITRES ? 
                                    project.PeriodeSansRemboursement + " mois"
                                :
                                project?.TypologieRemboursementCode &&
                                references.typologie?.[project.TypologieRemboursementCode]
                            }
                        </span>
                </div>
            </div>
            <div 
                css={{
                    display:"flex", 
                    flexDirection:"row", 
                    justifyContent:"space-between", 
                    alignItems:"center", 
                    fontSize: ".9em",
                    marginBottom: "1em"
                }}
            >
                <div 
                    css={{
                        width:"50%", 
                        display:"flex", 
                        flexDirection:"column"
                    }}
                >
                    <span 
                        css={{ 
                            fontWeight: "bold" 
                        }}
                    >
                        <Icon name="flag checkered" size="large" />
                        Durée
                    </span>
                    <span>
                        {project.MaturiteCreditCitoyen ? `${project.MaturiteCreditCitoyen} mois` : ""}
                    </span>
                </div>
                <div 
                    css={{
                        width:"50%", 
                        display:"flex", 
                        flexDirection:"column"
                        }}
                >
                    <span 
                        css={{ 
                        fontWeight: "bold" 
                        }}
                    >
                        <Icon name="calculator" size="large" />
                        Échéances
                    </span>
                    <span>
                        {project?.PeriodiciteEcheanceCitoyenCode &&
                            references.periodicity?.[
                            project.PeriodiciteEcheanceCitoyenCode
                        ]}
                    </span>
                </div>
            </div>
            {
                <div 
                    css={{
                        display:"flex", 
                        flexDirection:"row", 
                        justifyContent:"center", 
                        alignItems:"center", 
                        fontSize: ".9em",
                        marginBottom: "1em"
                    }}
                >
                        <div 
                            css={{
                                width:"50%", 
                                display:"flex", 
                                flexDirection:"column"
                            }}
                        >
                            <span 
                                css={{ 
                                    fontWeight: "bold" 
                                }}
                            >
                                <Icon name="flag checkered" size="large" />
                                Type d'emprunt
                            </span>
                            <span>
                                {TypeEmpruntLibelle.find(tel => tel.Code === project.TypeEmpruntCode)?.Libelle}
                            </span>
                        </div>
                        <div 
                            css={{
                                width:"50%", 
                                display:"flex", 
                                flexDirection:"column",
                                alignItems:"start"
                            }}
                        >
                            <span 
                                css={{ 
                                    fontWeight: "bold",
                                    display:"flex", 
                                    flexDirection:"row",
                                    alignItems:"center"
                                }}
                            >
                                <Icon name="money bill alternate" size="large" />
                                Investissement max
                            </span>
                            <span>
                                {project.MontantMaximalInvestissement ? project.MontantMaximalInvestissement / 100 : 0} €
                            </span>
                        </div>
                </div>
            }
            <div 
                className="resp-padding-bottom"
                css={{
                    display:"flex", 
                    flexDirection:"row", 
                    justifyContent:"space-around", 
                    alignItems:"center", 
                    fontSize: ".9em"
                }}
            >
                <div>
                    <a href="/risques" target="_blank" css={{fontWeight: "bold"}}>Risques</a>
                </div>
                <div>
                    <a href="/faq-page" target="_blank" css={{fontWeight: "bold"}}>FAQ</a>
                </div>
                <div>
                    <a href="/fiscalite" target="_blank" css={{fontWeight: "bold"}}>Fiscalité</a>
                </div>
            </div>
        </div>
    );
}
