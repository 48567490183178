import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Grid, Card, Popup } from "semantic-ui-react"
import { BankProvider } from "../../model/entities/bank-provider"
import { AuthenticationState } from "../../model/state/authentication-state"
import { publicService } from "../../services/public-service"
import { referenceService } from "../../services/reference-service"
import { ApplicationState } from "../../store"
import { ProjetLine } from "../projet/projet-line"
import { InvestmentPaneSelection } from "./investment-pane-selection"
import { StepDTO } from "react-form-stepper/dist/components/Step/StepTypes"
import { HorizontalStepper } from "../generic/horizontal-stepper"
import { ProjetListItem } from "../../model/dto/projet-list-item"
import { LoadingSpinner } from "../generic/loading-spinner"
import { userService } from "../../services/authentication-redux-service"
import { InvestResponse, InvestissementHistoryResponseListResponse, UserInfoResponse} from "../../model/dto/response"
import { WorkflowType } from "../../model/entities/workflow-type"
import { FlowStep } from "../../model/entities/flow-step"
import { WorkflowListData } from "../../model/dto/util/workflow-list-data"
import { projetService } from "../../services/projet-service"
import { trackPromise } from "react-promise-tracker"
import { investmentService } from "../../services/investment-service"
import { StatutInvestissement } from "../../model/entities/statut-investissement"
import { ModePaiement } from "../../model/entities/mode-paiement"

interface Props {
    projetId: number,
    isDon?: boolean
}

export function InvestFormV2({projetId, isDon}: Props) {
    const [investSteps, setInvestSteps] = useState<WorkflowListData[]>([])
    const [activeStepKey, setActiveStepKey] = useState<string>(FlowStep.MONTANT)
    const [projet, setProjet] = useState<ProjetListItem>()
    const [montantInvestissement, setMontantInvestissement] = useState<number | string>("")
    const [montantPercu, setMontantPercu] = useState<string>("0 €")
    const [isContratChecked, setIsContratChecked] = useState<boolean>(false)
    const [selectedProvider, setSelectedProvider] = useState<BankProvider | undefined>(undefined)
    const [score, setScore] = useState(0)
    const [reponseNumeroList, setReponseNumeroList] = useState<number[]>([])
    const [errorMessage, setErrorMessage] = useState("")
    const [isErrorDisplayed, setIsErrorDisplayed] = useState(false)
    const [userInfo, setUserInfo] = useState<UserInfoResponse>();
    const [showAttentePaiement, setShowAttentePaiement] = useState<boolean>(false);
    const [virementResponse, setVirementResponse] = useState<InvestResponse>()
    const [isResumeVirement, setIsResumeVirement] = useState<boolean>(false)
    const [montantDisponibleVillyz, setMontantDisponibleVillyz] = useState<number | undefined>()
    const [userSumPrevisionnelForProject, setUserSumPrevisionnelForProject] = useState<number | undefined>()
    const [projectSumPrevisionnel, setProjectSumPrevisionnel] = useState<number | undefined>()
    const [delaiReservationVirement, setDelaiReservationVirement] = useState<string>("")
    const [hasUserInvestimentsByVirementEnCours, setHasUserInvestimentsByVirementEnCours] = useState(false)

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    useEffect(() => {
        const InvestWorkflowBody = {
            WorkflowTypeCode: WorkflowType.PAIEMENT_INVESTISSEUR_SIMPLE
        }
        
        trackPromise(referenceService.getWorkflowStepListByWorkflowTypeCode(InvestWorkflowBody)
        .then(response => {
            setInvestSteps(response.WorkflowStepList!)
        }))

        userService.getDelaiReservationVirement(loginProps?.oauth?.userId, loginProps?.oauth)
        .then(response => {
            setDelaiReservationVirement(response.DelaiReservationVirement!)
        })   

        publicService.getProjetItemById(projetId)
        .then(response => {
            setProjet(response.ProjetCardInfo!)
        })

        userService.getResultatTestConnaissance(loginProps.oauth?.userId, loginProps.oauth)
        .then(response => {
            setScore(response.Score!)
            setReponseNumeroList(response.ReponseNumeroList!)
        })

        userService.getUserInfo(loginProps?.oauth?.userId, loginProps?.oauth)
        .then(response => {
            setUserInfo(response)
        })

        userService.calculateMontantCompteVillyz(loginProps.oauth?.userId, loginProps.oauth)
        .then(response => {
            if(response.IsTraitementOk){
                setMontantDisponibleVillyz(Math.trunc(response.MontantCompteVillyz! / 100))
            }
        })

        userService.calculateUserSumPrevisionnelForProject(loginProps.oauth?.userId, loginProps.oauth, projetId, isDon!)
        .then(response => {
            if(response.IsTraitementOk){
                setUserSumPrevisionnelForProject(response.MontantSumPrevisionnel! / 100)
            }
        })

        projetService.calculateMontantPrevisionnelCollecte(projetId, loginProps.oauth, isDon!)
        .then(response => {
            if(response.IsTraitementOk){
                setProjectSumPrevisionnel(response.MontantPrevisionnelCollecte! / 100)
            }
        })

        trackPromise(investmentService.getInvestmentHistory(loginProps?.user?.UserId, loginProps?.oauth)
        .then((container: InvestissementHistoryResponseListResponse) => {
          container.InvestissementHistoryResponseList.some(invest => (invest?.StatutInvestissement?.Code === StatutInvestissement.INITIE || invest?.StatutInvestissement?.Code === StatutInvestissement.EN_COURS) && invest?.ModePaiementCode === ModePaiement.VIREMENT_CLASSIQUE) ? setHasUserInvestimentsByVirementEnCours(true) : setHasUserInvestimentsByVirementEnCours(false)
        }));

    }, [])

    const sortedInvestSteps = useMemo(()=>{
        if(investSteps.length > 0){
            return investSteps.sort((a,b)=>{
                if(a?.Order! > b?.Order!)
                    return 1
                else if(a?.Order! < b?.Order!)
                    return -1
                else
                    return 0
            })
        }
        else{
            return []
        }
    }, [investSteps])

    const investStepsMap = useMemo(()=>{
        return sortedInvestSteps.map(investStep => {
            return {
                key: investStep.Key,
                value: sortedInvestSteps.indexOf(investStep)
            }
        })
    }, [sortedInvestSteps])

    const onValidateMontant = (montant: number, montantPercu: string, isContratChecked: boolean, isNextStep?: boolean) => {
        const body = {
            WorkflowTypeCode: WorkflowType.PAIEMENT_INVESTISSEUR_SIMPLE,
            CurrentFlowStep: FlowStep.MONTANT,
            RessourceId: userInfo?.UserId!,
            IsSkiped: false
        }
        
        if(isNextStep) {
            referenceService.getNextStep(body).then(response => {
                setActiveStepKey(response.NextFlowStepCode!)
            })  
        }
        
        setMontantInvestissement(montant)
        setMontantPercu(montantPercu)
        setIsContratChecked(isContratChecked)
    }

    const onReturnClick = () => {
        setActiveStepKey(FlowStep.MONTANT)
    }

    const onSelectBranch = (provider: BankProvider) => {
        setSelectedProvider(provider)
    }

    const onSubmitImpossible = (errorMessage: string) => {
        setErrorMessage(errorMessage)
        setIsErrorDisplayed(true)
    }

    const onClickAttentePaiement = () => {
        setShowAttentePaiement(true);
    };

    const onVirementSuccessHandler = (response: InvestResponse) => {
        if(response.IsTraitementOk){
            setVirementResponse(response)
            setIsResumeVirement(true)
        }
    }

    const disabledAttentePaiement = () => {
        setShowAttentePaiement(false)
    }
    
    return (
        <div>
            <Grid stackable>
                <Grid.Row
                    centered
                    css={{
                        marginTop:"50px"
                    }}
                >
                    <HorizontalStepper
                        elements={investSteps?.map(element => {
                            return {
                                label: element.Libelle
                            } as StepDTO
                        })}
                        activeStep={investStepsMap.find(e => e.key === activeStepKey)?.value}
                    />
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column width={4}>
                        <Grid.Row css={{margin:"20px 10px"}}>
                            <h2 css={{
                               color: "#2c3c54"
                            }}>
                                Projet sélectionné
                            </h2>
                        </Grid.Row>
                        <Grid.Row>
                            {projet && (
                                    <Card.Group itemsPerRow={1} doubling stackable>
                                        <ProjetLine
                                            projet={projet}
                                            isCardClickable={false}
                                            isDon={isDon}
                                        />
                                    </Card.Group>
                                ) || (
                                    <div></div>
                                )
                            }
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Grid.Row css={{margin:"20px 10px"}}>
                            <Grid>
                                {activeStepKey === FlowStep.PAIEMENT && !isResumeVirement &&(
                                    <Grid.Column width={1} verticalAlign="middle">
                                        <Popup
                                            content="Revenir à la sélection du montant"
                                            key="retourMontant"
                                            trigger={
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg" 
                                                    width="20" 
                                                    height="20" 
                                                    viewBox="0 0 24 24"
                                                    cursor="pointer"
                                                    color="#2c3c54"
                                                    onClick={onReturnClick}
                                                >
                                                    <path d="M18.885 3.515c-4.617-4.618-12.056-4.676-16.756-.195l-2.129-2.258v7.938h7.484l-2.066-2.191c2.82-2.706 7.297-2.676 10.073.1 4.341 4.341 1.737 12.291-5.491 12.291v4.8c3.708 0 6.614-1.244 8.885-3.515 4.686-4.686 4.686-12.284 0-16.97z"/>
                                                </svg>
                                            }
                                        />
                                        
                                    </Grid.Column>
                                )}
                                <Grid.Column width={9}>
                                    <h2 css={{
                                    color: "#2c3c54"
                                    }}>
                                        Investissement sécurisé
                                    </h2>
                                </Grid.Column>
                            </Grid>
                        </Grid.Row>
                        <Grid.Row>
                            <LoadingSpinner>
                                <InvestmentPaneSelection
                                    projet={projet}
                                    montant={montantInvestissement}
                                    montantPercuValue={montantPercu}
                                    isContratCheckedValue={isContratChecked}
                                    activeStepKey={activeStepKey}
                                    selectedProvider={selectedProvider}
                                    numeroReponseTestList={reponseNumeroList}
                                    score={score}
                                    errorMessage={errorMessage}
                                    isErrorDisplayed={isErrorDisplayed}
                                    onValidateMontant={onValidateMontant}
                                    onSelectBranch={onSelectBranch}
                                    onSubmitImpossible={onSubmitImpossible}
                                    userInfo={userInfo}
                                    onClickAttentePaiement={onClickAttentePaiement}
                                    showAttentePaiement={showAttentePaiement}
                                    onVirementSuccess={onVirementSuccessHandler}
                                    virementResponse={virementResponse}
                                    isResumeVirement={isResumeVirement}
                                    montantDisponibleVillyz={montantDisponibleVillyz}
                                    userSumPrevisionnelForProject={userSumPrevisionnelForProject}
                                    projectSumPrevisionnel={projectSumPrevisionnel}
                                    disabledAttentePaiement={disabledAttentePaiement}
                                    delaiReservationVirement={delaiReservationVirement}
                                    hasUserInvestimentsByVirementEnCours={hasUserInvestimentsByVirementEnCours}
                                    isDon={isDon}
                                />
                            </LoadingSpinner>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}