import React from "react"
import { Accordion, Tab, Container, Header } from 'semantic-ui-react'
import { section } from "./styles"
import { citoyenPanelGeneral, citoyenPanelPretParticipatifCitoyen, citoyenPanelRisques, citoyenPanelFiscalité, collectivitePanelGeneral, collectivitePanelPublicationDesProjetsSurLaPlateformeEtDéroulementDesCampagnesDeFinancementCitoyen, collectivitePanelRetraitDesFondsCollectésEtRemboursements} from "./faq-questions";

const panes = [
    {
      menuItem: 'Citoyen',
      render: () =>(
        <Tab.Pane css={{backgroundColor:"transparent !important"}}>  
            <Header as="h2" color="blue" css={{textAlign : "center"}}>
                Général
            </Header>
            <Accordion defaultActiveIndex={0} fluid styled panels={citoyenPanelGeneral} />
            <Header as="h2" color="blue" css={{textAlign : "center"}}>
                Investissement citoyen
            </Header>
            <Accordion defaultActiveIndex={0} fluid styled panels={citoyenPanelPretParticipatifCitoyen} />
            <Header as="h2" color="blue" css={{textAlign : "center"}}>
                Risques
            </Header>
            <Accordion defaultActiveIndex={0} fluid styled panels={citoyenPanelRisques} />
            <Header as="h2" color="blue" css={{textAlign : "center"}}>
                Fiscalité
            </Header>
            <Accordion defaultActiveIndex={0} fluid styled panels={citoyenPanelFiscalité} />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Porteur de projet',
      render: () => (
        <Tab.Pane css={{backgroundColor:"transparent !important"}}>
            <Header as="h2" color="blue" css={{textAlign : "center"}}>
                Général
            </Header>
            <Accordion defaultActiveIndex={0} fluid styled panels={collectivitePanelGeneral} />
            <Header as="h2" color="blue" css={{textAlign : "center"}}>
                Campagnes de financement citoyen
            </Header>
            <Accordion defaultActiveIndex={0} fluid styled panels={collectivitePanelPublicationDesProjetsSurLaPlateformeEtDéroulementDesCampagnesDeFinancementCitoyen}/>
            <Header as="h2" color="blue" css={{textAlign : "center"}}>
                Retrait des fonds et remboursement
            </Header>
            <Accordion defaultActiveIndex={0} fluid styled panels={collectivitePanelRetraitDesFondsCollectésEtRemboursements}/>
        </Tab.Pane>
      )
    }
]

export function FaqPage(){
    return (
        <div css={[section]}>
            <Container>
            <Header as="h1" color="blue" css={{textAlign : "center", marginTop : "-50px"}}>
                FOIRE AUX QUESTIONS​
            </Header>
            <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
            </Container>
        </div>
    )
}