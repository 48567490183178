import React, { useMemo, useState } from "react"
import { Button, Container, Icon, Popup } from "semantic-ui-react"
import { StatutProjet } from "../../../../model/entities/statut-projet";
import { NavLink } from "react-router-dom";
import { PaginatedTable } from "../../../generic/paginated-table/paginated-table"
import { SubmitButton } from "../../../generic/submit-button";
import { AuthenticationState } from "../../../../model/state/authentication-state";
import { ApplicationState } from "../../../../store";
import { useSelector } from "react-redux";
import { ContratDePretResponse, UserDonHistoryResponse } from "../../../../model/dto/response";
import { format } from "date-fns";
import { Profil } from "../../../../model/entities/profil";
import { LoadingSpinner } from "../../../generic/loading-spinner";
import { StatutInvestissement } from "../../../../model/entities/statut-investissement";
import { ModePaiement } from "../../../../model/entities/mode-paiement";

type Props = {
    isLoading?: boolean,
    userDonHistory?: UserDonHistoryResponse[],
    handleRetourRemboursement?: ()=>void,
}

export function DonPane({isLoading, userDonHistory, handleRetourRemboursement}: Props) {
    const [currentDon, setCurrentDon]  = useState<UserDonHistoryResponse>();
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    const mappingStatutProjet = [
        {
        code: StatutProjet.PUBLIE,
        message: 'En cours'
        },
        {
        code: StatutProjet.CLOTURE,
        message: 'Clôturée'
        },
        {
        code: StatutProjet.DEBOUCLAGE_POSSIBLE,
        message: 'Clôturée'
        },
        {
        code: StatutProjet.DEBOUCLAGE_DEMANDE,
        message: 'Clôturée'
        },
        {
        code: StatutProjet.DEBOUCLAGE_VALIDE,
        message: 'Clôturée'
        },
        {
        code: StatutProjet.DEBOUCLAGE_FINALISE,
        message: 'Clôturée'
        },
        {
        code: StatutProjet.FINANCE,
        message: 'Terminée'
        }
    ]

    const buttonLabel = useMemo(() => {
        if(loginProps?.oauth?.profilCode === Profil.INVESTISSEUR) {
            return "Découvrir les projets"
        }

        if(loginProps?.oauth?.profilCode === Profil.PORTEUR_PROJET) {
            return "Publier un projet"
        }

        return ""
    }, [])

    const tabLabel = useMemo(() => {
        if(loginProps?.oauth?.profilCode === Profil.PORTEUR_PROJET) {
            return "Mes projets"
        }

        return ""
    }, [])

    const onDownloadSuccessContrat = (response: ContratDePretResponse ) => {
        const byteCharacters = atob(response.File!);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: ''});
        
        const aEle = document.createElement('a');     // Create a label
        const href = window.URL.createObjectURL(blob);       // Create downloaded link
        aEle.href = href;
        aEle.download = "Contrat_Pret.pdf";  // File name after download
        document.body.appendChild(aEle);
        aEle.click();     // Click to download
        document.body.removeChild(aEle); // Download complete remove element
        window.URL.revokeObjectURL(href)
    }

    const authorizedStatusDownloadContrat: string[] = [
        StatutProjet.DEBOUCLAGE_VALIDE,
        StatutProjet.FINANCE,
        StatutProjet.DEBOUCLAGE_FINALISE
    ]

    const popupMessage = `Vous avez effectué une opération via virement, votre place est réservée pendant 10 jours. Nous vous avons envoyé les informations pour effectuer votre virement par email. Si nous ne recevons pas les fonds dans ce délai, nous ne pourrons plus vous assurer la possibilité de participer à la collecte.`

    const displayConditionForReservedInfo =  (element: UserDonHistoryResponse) => {
        if(element && (element.StatutDonCode === StatutInvestissement.INITIE || element.StatutDonCode === StatutInvestissement.EN_COURS) && element.ModePaiementCode === ModePaiement.VIREMENT_CLASSIQUE ){
            return element.IsReserve ? (
               <Popup 
                    content={popupMessage}
                    position='top right'
                    size='mini'
                    on='hover'
                    pinned
                    mouseEnterDelay={200}
                    mouseLeaveDelay={200}
                    hideOnScroll
                    trigger={
                        <span>{element.StatutDonLibelle} - Réservé 
                        <Icon
                            name="info circle"
                            size="large"
                            color="blue"
                            css={{paddingLeft: "5px"}}
                        />
                        </span>
                    }
               />
            ) :(
                <Popup 
                    content={popupMessage}
                    position='top right'
                    size='mini'
                    on='hover'
                    pinned
                    mouseEnterDelay={200}
                    mouseLeaveDelay={200}
                    hideOnScroll
                    trigger={
                        <span>{element.StatutDonLibelle} - Non réservé 
                        <Icon
                            name="info circle"
                            size="large"
                            color="blue"
                            css={{paddingLeft: "5px"}}
                        />
                        </span>
                    }
               />
            )
        }
        return element.StatutDonLibelle
    }
    
    return (
        <Container>
            {tabLabel &&
                <h1 className="py-8 text-3xl">{tabLabel}</h1> 
            }
            <LoadingSpinner height={100}>
            {!isLoading && (userDonHistory?.length ? (loginProps.oauth?.profilCode === Profil.INVESTISSEUR && (
                <PaginatedTable 
                    elements={userDonHistory}
                    elementsPerPage={10}
                    headers={
                        [
                            'Date',
                            'Projet', 
                            'Etat de la collecte', 
                            'Montant du don', 
                            'Statut du don',
                            'Contrat de don (.pdf)'
                        ]
                    }
                    columnSelector={(element: UserDonHistoryResponse) => {
                    return {
                        values: [
                            element.DateDon ? format(new Date(element.DateDon), "dd/MM/yyyy") : '',
                            <a href={`/projets/${element.ProjetSlug}/don`} target="_blank">{element.Titre}</a>,
                            mappingStatutProjet.find((c) => c.code === element.StatutDonProjetCode)?.message,
                            element.MontantDon ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(element.MontantDon / 100) : '',
                            displayConditionForReservedInfo(element),
                            <div css={{display:"inline-block"}}>
                                {currentDon?.StatutDonLibelle === StatutInvestissement.REUSSI && (
                                    <SubmitButton
                                    label="Télécharger contrat"
                                    />
                                ) || (
                                    <></>
                                )}    
                            </div>
                        ]
                    }
                }}
                />
                ) 
            ) 
            : 
            (
                <div className="text-center p-8 text-xl">
                    <span className="font-bold">
                        Vous n'avez encore fait un don dans aucun projet
                    </span>
                    <p>
                        Effectuez une première contribution pour suivre l'évolution de
                        vos dons
                    </p>
                </div>
            ))}
            </LoadingSpinner>
            <div className="text-center py-8">
                <Button as={NavLink} to="/projets" size="big" primary>
                    {buttonLabel}
                </Button>
            </div>
        </Container>
    )
}