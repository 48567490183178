import React, { useEffect, useMemo, useState } from "react";
import { Button, Container, Icon, Modal, Segment, Tab } from "semantic-ui-react";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { InvestissementHistoryResponse, InvestissementHistoryResponseListResponse, ProfilCode, RecentPayoutListResponse, UserDonHistoryResponse } from "../../../model/dto/response";
import { RetraitFondsModal } from "./retrait-fonds-modal"
import { InvestissementsPane } from "./tab/investissements-pane"
import { EcheancesPane } from "./tab/echeances-pane"
import { RetraitPane } from "./tab/retrait-pane"
import { RemboursementPane } from "./tab/remboursement-pane"
import { Profil } from "../../../model/entities/profil";
import { userService } from "../../../services/authentication-redux-service";
import { OnboardingStatus } from "../../../model/entities/onboarding-status";
import { useNavigate } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { investmentService } from "../../../services/investment-service";
import { StatutInvestissement } from "../../../model/entities/statut-investissement";
import { HistoRetraitCitoyen } from "../../../model/entities/histo-retrait-citoyen";
import { DonPane } from "./tab/don-pane";

type Props = {
};

export function DashboardCitoyen({}: Props) {
  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
  const [isEdited, setIsEdited] = useState(false)
  const [index, setIndex] = useState<number>(0);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [openPopUpRetrait, setOpenPopUpRetrait] = useState(false);
  const [errorMessageOpenPopUpRetrait, setErrorMessageOpenPopUpRetrait] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [histoInvestissement, setHistoInvestissement] = useState<InvestissementHistoryResponse[]>([]);
  const [hasUserInvestimentsEnCours, setHasUserInvestimentsEnCours] = useState(false)
  const [errorInvestEnCoursPopUpRetrait, setErrorInvestEnCoursPopUpRetrait] = useState(false)
  const [histoRetrait, setHistoRetrait] = useState<HistoRetraitCitoyen[]>()
  const [reload, setReload] = useState(false)
  const [openPopUpCancelReservation, setOpenPopUpCancelReservation] = useState(false)
  const [openBandeauAnnulationReservationError, setOpenBandeauAnnulationReservationError] = useState(false)
  const [valueWallet, setValueWallet] = useState<number | undefined>()
  const [userDonHistory, setUserDonHistory] = useState<UserDonHistoryResponse[]>([]);
  const navigate = useNavigate()

  useEffect(() => {
    if(loginProps?.oauth?.profilCode === Profil.INVESTISSEUR) {
      setIsLoading(true)

      if(loginProps?.oauth?.userId) {
        trackPromise(investmentService.getInvestmentHistory(loginProps?.user?.UserId, loginProps?.oauth)
        .then((container: InvestissementHistoryResponseListResponse) => {
          setHistoInvestissement(container.InvestissementHistoryResponseList!)
          container.InvestissementHistoryResponseList.some(invest => invest?.StatutInvestissement?.Code === StatutInvestissement.INITIE || invest?.StatutInvestissement?.Code === StatutInvestissement.EN_COURS) ? setHasUserInvestimentsEnCours(true) : setHasUserInvestimentsEnCours(false)
          setIsLoading(false)
        }));
      }
      
      if(loginProps?.oauth?.etablissementId) {
        trackPromise(investmentService.getUserDonHistory(loginProps?.user?.UserId, loginProps?.oauth))
        .then(response => {
          if(response.IsTraitementOk){
            setUserDonHistory(response.UserDonHistoryResponseList!)
          }
        })
      }

      trackPromise(userService.calculateMontantCompteVillyz(loginProps?.user?.UserId, loginProps?.oauth))
      .then(response => {
        if(response.IsTraitementOk){
          setValueWallet(response.MontantCompteVillyz! / 100)
        }
      })
    }

    if(loginProps?.oauth?.profilCode === Profil.PORTEUR_PROJET) {
      setIsLoading(true)
      trackPromise(investmentService.getInvestmentCollectiviteHistory(loginProps?.oauth?.etablissementId, loginProps?.oauth)
      .then((container: InvestissementHistoryResponseListResponse) => {
        setHistoInvestissement(container.InvestissementHistoryResponseList!)
        container.InvestissementHistoryResponseList.some(invest => invest?.StatutInvestissement?.Code === StatutInvestissement.INITIE || invest?.StatutInvestissement?.Code === StatutInvestissement.EN_COURS) ? setHasUserInvestimentsEnCours(true) : setHasUserInvestimentsEnCours(false)
        setIsLoading(false)
      }));
    }

    trackPromise(investmentService.getAllPayouts(loginProps?.oauth)
      .then((response: RecentPayoutListResponse) => {
        if(response.IsTraitementOk) {
          setHistoRetrait(response.HistoRetraitList!)
          setIsLoading(false)
        }
    }))
  }, []);

  useEffect(()=> {
    if(reload){
      if(loginProps?.oauth?.profilCode === Profil.INVESTISSEUR) {
        setIsLoading(true)

        trackPromise(userService.calculateMontantCompteVillyz(loginProps?.user?.UserId, loginProps?.oauth))
        .then(response => {
          if(response.IsTraitementOk){
            setValueWallet(response.MontantCompteVillyz! / 100)
          }
        })

        if(loginProps?.oauth?.etablissementId) {
          trackPromise(investmentService.getUserDonHistory(loginProps?.user?.UserId, loginProps?.oauth))
          .then(response => {
            if(response.IsTraitementOk){
              setUserDonHistory(response.UserDonHistoryResponseList!)
            }
          })
        }
        
        if(loginProps?.oauth?.userId) {
          trackPromise(investmentService.getInvestmentHistory(loginProps?.user?.UserId, loginProps?.oauth)
          .then((container: InvestissementHistoryResponseListResponse) => {
            setHistoInvestissement(container.InvestissementHistoryResponseList!)
            container.InvestissementHistoryResponseList.some(invest => invest?.StatutInvestissement?.Code === StatutInvestissement.INITIE || invest?.StatutInvestissement?.Code === StatutInvestissement.EN_COURS) ? setHasUserInvestimentsEnCours(true) : setHasUserInvestimentsEnCours(false)
            setIsLoading(false)
          }));
        }
      }

      trackPromise(investmentService.getAllPayouts(loginProps?.oauth)
        .then((response: RecentPayoutListResponse) => {
          if(response.IsTraitementOk) {
            setHistoRetrait(response.HistoRetraitList!)
            setIsLoading(false)
          }
        }))
  
      if(loginProps?.oauth?.profilCode === Profil.PORTEUR_PROJET) {
        setIsLoading(true)
        trackPromise(investmentService.getInvestmentCollectiviteHistory(loginProps?.oauth?.etablissementId, loginProps?.oauth)
        .then((container: InvestissementHistoryResponseListResponse) => {
          setHistoInvestissement(container.InvestissementHistoryResponseList!)
          container.InvestissementHistoryResponseList.some(invest => invest?.StatutInvestissement?.Code === StatutInvestissement.INITIE || invest?.StatutInvestissement?.Code === StatutInvestissement.EN_COURS) ? setHasUserInvestimentsEnCours(true) : setHasUserInvestimentsEnCours(false)
          setIsLoading(false)
        }));
      }
      setReload(false)
    }
  },[reload])
  
  const lastTenHistoRetrait = useMemo(() => {
    return histoRetrait?.slice(0,11)
  },[histoRetrait])

  const handleRetourRemboursement= ()=>{
    setReload(true)
    setIndex(3);
    setOpenPopUp(true);
  }

  const handleRetraitFonds= ()=>{
    setReload(true)
    setIsEdited(false)
    setOpenPopUpRetrait(true);
  }

  const handleCancelReservation= () => {
    setReload(true)
    setOpenPopUpCancelReservation(true)
  }

  const handleCancelReservationError= () => {
    setOpenBandeauAnnulationReservationError(true)
  }

  const isDon = useMemo(()=>{
    if(loginProps.oauth?.etablissementId && loginProps.oauth.profilCode === ProfilCode.INVESTISSEUR) {
      return true
    }
    else {
      return false
    }
  }, [])

  const profilLink = {
    color: "#cc0033",
    textDecoration: "underline",
    cursor: "pointer",
  }

  const panes = [
    ...(isDon ? [] : [
      {
        menuItem: 'Mes investissements',
        render: () => {
          return (
            <Tab.Pane attached={false} 
              css={{
                background: 'transparent !important',
                border: 'none !important'
              }}
            >
              <InvestissementsPane 
                isLoading={isLoading}
                isDon={isDon}
                histoInvestissement={histoInvestissement}
                handleRetourRemboursement={handleRetourRemboursement}
                handleCancelReservation={handleCancelReservation}
                handleCancelReservationError={handleCancelReservationError}
              />
            </Tab.Pane>
          )
        },
      },
    ]),
    ...(!isDon ? [] : [
      {
        menuItem: 'Mes dons',
        render: () => {
          return (
            <Tab.Pane attached={false} 
              css={{
                background: 'transparent !important',
                border: 'none !important'
              }}
            >
              <DonPane 
                isLoading={isLoading}
                userDonHistory={userDonHistory}
                handleRetourRemboursement={handleRetourRemboursement}
              />
            </Tab.Pane>
          )
        },
      },
    ]),
    ...(isDon ? [] : [
      {
        menuItem: 'Mes échéances',
        render: () => {
          return (
            <Tab.Pane attached={false} 
              css={{
                background: 'transparent !important',
                border: 'none !important'
              }}
            >
              <EcheancesPane />
            </Tab.Pane>
          )
        },
      },
    ]),
    {
      menuItem: 'Mes retraits',
      render: () => {
        return (
          <Tab.Pane attached={false} 
            css={{
              background: 'transparent !important',
              border: 'none !important'
            }}
          >
            <RetraitPane histoRetrait={lastTenHistoRetrait}/>
          </Tab.Pane>
        )
      },
    },
    {
      menuItem: 'Mes remboursements',
      render: () => {
        return (
          <Tab.Pane attached={false} 
            css={{
              background: '#ECF0F1 !important',
              border: 'none !important'
            }}
          >
            <RemboursementPane />
          </Tab.Pane>
        )
      },
    }
  ]

  return (
    <div>
      <div>
        <Container>
          {loginProps?.oauth?.profilCode === Profil.INVESTISSEUR && openPopUp && 
            <Segment 
              css={{
                marginTop:"20px !important",
                border:"2px solid #BED3AF !important", 
                backgroundColor:"#E0F3D4 !important", 
                display:"flex", 
                flexDirection:"row", 
                alignItems:"center"
            }}>
                <div css={{
                  position: "absolute", 
                  right:"5px", 
                  top:"5px", 
                  cursor:"pointer"
                  }} 
                  onClick={()=>setOpenPopUp(false)}
                >
                    <Icon name="times" 
                      css={{
                        color:"#B0C6A3", 
                        "&:hover": {color: "#71797E"}
                      }} 
                      size="large">
                    </Icon>
                </div>
                <div css={{flexBasis: "10%", alignSelf:"flex-start"}}>
                  <Icon name="check" css={{color:"#5D7150"}} size="huge"/>
                </div>
                <div css={{flexBasis: "90%"}}>
                  <p 
                    css={{
                      color:"#5D7150", 
                      fontSize:"16px", 
                      fontWeight:"bold"
                    }}
                  >
                  Votre demande de remboursement a bien été prise en compte. 
                  Vous pouvez suivre son avancement depuis votre tableau de bord sur l'onglet "Mes remboursements".
                  </p>
                </div>
            </Segment>
          }
          
          {loginProps?.oauth?.profilCode === Profil.INVESTISSEUR  && openPopUpRetrait &&
            <Segment 
              css={{
                position:"relative", 
                marginTop:"30px !important",
                backgroundColor:"#D1E9F7 !important",
                border: "solid 1px #005879 !important", 
                color: "#005879 !important", 
                padding: "26px 10px !important",  
                display:"flex", 
                flexDirection:"row", 
                alignItems:"center"
              }}
            >
              <div 
                css={{
                  position: "absolute", 
                  right:"5px", 
                  top:"5px", 
                  cursor:"pointer"
                }} 
                onClick={()=>setOpenPopUpRetrait(false)}
              >
                <Icon name="times" css={{color:"#005879", "&:hover": {color: "#327993"}}} size="large"></Icon>
              </div>

              <div css={{flexBasis: "4%", alignSelf:"center"}}>
                <Icon name='info circle' size='huge' css={{color:"#005879"}}/>
              </div>
              
              <div css={{flexBasis: "96%"}}>
                <p 
                  css={{
                    color:"#005879", 
                    fontSize:"18px", 
                    fontWeight:"bold",
                    marginLeft: "10px"
                  }}
                >
                  Votre opération est en cours de traitement, vous recevrez vos fonds dans quelques jours. Vous pouvez suivre l'avancement du transfert sur votre tableau de bord dans votre onglet "Mes retraits".
                </p>
              </div>
            </Segment>
          }

          {loginProps?.oauth?.profilCode === Profil.INVESTISSEUR && errorMessageOpenPopUpRetrait && 
            <Segment
              css={{
                position: "relative",
                marginTop: "30px !important",
                backgroundColor: "#fce4e4 !important",
                border: "solid 1px #cc0033 !important",
                color: "#005879",
                padding: "25px 10px !important",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div css={{ flexBasis: "4%", alignSelf: "center" }}>
                <Icon name="remove circle" size="big" css={{ color: "#cc0033" }} />
              </div>

              <div
                css={{
                  flexBasis: "96%",
                  color: "#cc0033",
                  fontWeight: "bold",
                }}
              >
                <p css={{ marginLeft: "10px", fontSize: "18px" }}>
                  Afin de pouvoir retirer des fonds, vous devez valider {" "}
                  <span onClick={() => {navigate("/profil")}} css={profilLink}>
                    votre profil.
                  </span>
                </p>
              </div>
            </Segment>
          }

          {loginProps?.oauth?.profilCode === Profil.INVESTISSEUR && errorInvestEnCoursPopUpRetrait && hasUserInvestimentsEnCours &&
            <Segment
            css={{
              position: "relative",
              marginTop: "30px !important",
              backgroundColor: "#fce4e4 !important",
              border: "solid 1px #cc0033 !important",
              color: "#005879",
              padding: "25px 10px !important",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <div css={{ flexBasis: "4%", alignSelf: "center" }}>
              <Icon name="remove circle" size="big" css={{ color: "#cc0033" }} />
            </div>

            <div
              css={{
                flexBasis: "96%",
                color: "#cc0033",
                fontWeight: "bold",
              }}
            >
              <p css={{ marginLeft: "10px", fontSize: "18px" }}>
              Vous avez actuellement un investissement en cours dans un ou plusieurs projets pour lequel(s) les sommes disponibles sur votre compte vont être réinvesties. Ainsi, vous ne pouvez pas les retirer. Néanmoins si vous désirez annuler cet investissement vous pouvez contacter investisseurs@villyz.fr
              </p>
            </div>
          </Segment>
          
          }

          {loginProps?.oauth?.profilCode === Profil.INVESTISSEUR && openPopUpCancelReservation && 
              <Segment 
                css={{
                  marginTop:"20px !important",
                  border:"2px solid #BED3AF !important", 
                  backgroundColor:"#E0F3D4 !important", 
                  display:"flex", 
                  flexDirection:"row", 
                  alignItems:"center"
              }}>
                  <div css={{
                    position: "absolute", 
                    right:"5px", 
                    top:"5px", 
                    cursor:"pointer"
                    }} 
                    onClick={()=>setOpenPopUpCancelReservation(false)}
                  >
                      <Icon name="times" 
                        css={{
                          color:"#B0C6A3", 
                          "&:hover": {color: "#71797E"}
                        }} 
                        size="large">
                      </Icon>
                  </div>
                  <div css={{flexBasis: "10%", alignSelf:"flex-start"}}>
                    <Icon name="check" css={{color:"#5D7150"}} size="huge"/>
                  </div>
                  <div css={{flexBasis: "90%"}}>
                    <p 
                      css={{
                        color:"#5D7150", 
                        fontSize:"16px", 
                        fontWeight:"bold"
                      }}
                    >
                    L'annulation de votre volonté d'investissement a bien été prise en compte.
                    </p>
                  </div>
              </Segment>
            }

          {loginProps?.oauth?.profilCode === Profil.INVESTISSEUR && openBandeauAnnulationReservationError && 
              <Segment 
              css={{
                position: "relative",
                marginTop: "30px !important",
                backgroundColor: "#f2dede !important",
                border: "solid 1px #cc0033 !important",
                color: "#005879",
                padding: "25px 10px !important",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}>
                  <div css={{
                    position: "absolute", 
                    right:"5px", 
                    top:"5px", 
                    cursor:"pointer"
                    }} 
                    onClick={()=>setOpenBandeauAnnulationReservationError(false)}
                  >
                      <Icon name="times" 
                        css={{
                          color:"#a94442", 
                          "&:hover": {color: "#71797E"}
                        }} 
                        size="large">
                      </Icon>
                  </div>
                  <div css={{flexBasis: "10%", alignSelf:"flex-start"}}>
                    <Icon name="times circle" css={{color:"#a94442"}} size="huge"/>
                  </div>
                  <div css={{flexBasis: "90%"}}>
                    <p 
                      css={{
                        color:"#a94442", 
                        fontSize:"16px", 
                        fontWeight:"bold"
                      }}
                    >
                    Il est impossible d'annuler un investissement pour lequel un paiement a été reçu et est en cours de traitement. 
                    </p>
                    <p 
                      css={{
                        color:"#a94442", 
                        fontSize:"16px", 
                        fontWeight:"bold"
                      }}
                    >
                    Désormais vous devez attendre la résolution du paiement pour demander un éventuel remboursement.
                    </p>
                  </div>
              </Segment>
            }
          
          <h1 className="py-8 text-3xl">Mon tableau de bord</h1>
          <div className="flex justify-center items-center">
            <div className="flex flex-col px-4 text-center text-xl text-gray-400">
              <span className="font-bold text-3xl">{valueWallet}€</span>
              <span >
                <Icon name="money" size="big" />
                {"   "}Somme disponible
              </span>
            </div>
            <div className="flex flex-col px-4">
              <div>
                <Button 
                  fluid 
                  primary 
                  onClick={() => {
                    setIsEdited(true);
                    if (loginProps?.user?.OnboardingStatus?.Code !== OnboardingStatus.VALIDE) {
                      setErrorMessageOpenPopUpRetrait(true);
                    }
                    if(hasUserInvestimentsEnCours){
                      setErrorInvestEnCoursPopUpRetrait(true)
                    }
                  }}
                >
                  <Icon name="minus circle" />
                  Retirer des fonds
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    
      <div className="pt-8">
        <Container>
          <Tab menu={{ secondary: true, pointing: true }} 
            panes={panes} 
            onTabChange={(e: any)=>setIndex(e.target.value)}
            activeIndex={index}
          />
        </Container>
        {loginProps?.user?.OnboardingStatus?.Code === OnboardingStatus.VALIDE && !hasUserInvestimentsEnCours &&
          <Modal  onClose={() => setIsEdited(false)} open={isEdited}>
            <Modal.Header>Retirer des fonds</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <RetraitFondsModal 
                  walletValue={valueWallet ?? 0}
                  onSubmitSuccess={() => setIsEdited(false)}
                  handleRetraitFonds={handleRetraitFonds}
                />
              </Modal.Description>
            </Modal.Content>
          </Modal>
        }
      </div>
    </div>
  );
}