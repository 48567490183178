import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Container, Grid, List, Image, Icon } from "semantic-ui-react";
import { ReactComponent as VillyLogo } from "./logo.svg";
import { InputField } from "../generic/input-field";
import { publicService } from "../../services/public-service";
import { NewsLetterBody } from "../../model/dto/body/inscription-user-newsletter-body";
import { AuthenticationState } from "../../model/state/authentication-state";
import { ApplicationState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { userService } from "../../services/authentication-redux-service";
import { SubmitButton } from "../generic/submit-button";
import { InscriptionUserNewsLetterResponse, UserInfoResponse } from "../../model/dto/response";
import { AuthenticationAction, CHANGE_IDENTITY } from "../../actions/authentication-actions";

const footerLinkColor = "rgba(255,255,255,0.7)"

export function Footer() {

  type FieldData = {
    value?: any,
    name?: string,
    isValid?: boolean
  };

  type FormData = {
    mail: FieldData,
  }

  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

  const [form, setForm] = useState<FormData>({ mail: {name: 'mail', isValid: true}})
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [openPopUpMessage, setOpenPopUpMessage] = useState(false)
  const dispatch = useDispatch()

  const body: NewsLetterBody = {
    Mail: form.mail.value
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean): void => {
    const fieldValue: string = e.target.value;
    setForm({...form, [fieldName!]: {value: fieldValue, name: fieldName, isValid: isValid}})
  }

  const onSubmitInfoSuccess = (response: InscriptionUserNewsLetterResponse) => {
    if(response.UserInfo) {
      const action: AuthenticationAction = { type: CHANGE_IDENTITY, user: response.UserInfo }
      localStorage.setItem("user_info", JSON.stringify(response.UserInfo))
      dispatch(action)
    }
  }

  const validateMandatory = (value : any) => {
    if (value) {
      const regex = /^[\w.+-]{1,64}@([a-zA-Z\d-]{2,252}\.[a-zA-Z\.]{2,7})$/
        return( regex.test(value))
    } else {
        return false
    }
  }

  const isFormValid = (): boolean => {
    return validateMandatory(form.mail.value)
  }

  const breakpoints = [576, 768, 992, 1200]
  const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`)

  const newsLetterBackground =  {
    backgroundColor: '#E0E8EB',
    color: '#000000',
    padding: '19px',
    marginBottom : '10px'
  }

  const btnStyles = {
    borderRadius: '0px 4px 4px 0px',
    fontWeight: 'bold',
    fontSize: '18px',
    flexWrap: 'wrap',
    padding: '8px 20px',
    fontFamily: "raleway-bold",
    [mq[2]] : {
      borderRadius: '4px'
    }
  }

  const btnWithBorder = {
    ...btnStyles,
    borderRadius: '4px !important',
  }
  
  return (
    <>
      <footer
        css={{ 
          background: "#005870", 
          color: "white", 
          bottom: '0', 
          width: '100%',
          marginTop: "50px",
          fontFamily:'raleway'
        }}
      >
        {/* NewsLetter - Message de confirmation */}
        {openPopUpMessage &&
          <div
            css={{
              backgroundColor : "#DFF0D8",
              color: "#3C763D",
              display:"flex", 
              alignItems : "center",
              justifyContent: "center",
              padding: '25px',
              marginBottom : '10px'
            }}
          >
            <div 
              onClick={() => {setOpenPopUpMessage(false)}}
              css={{
                position: "absolute", 
                right:"10px", 
                cursor:"pointer"
              }}
            >
                <Icon name="times" css={{color: '#3C763D !important'}} size="large"></Icon>
              </div>

            <div css={{flexBasis: "4%"}}>
              <Icon name="check" css={{color: "#3C763D"}} size="big"/>
            </div>

            <div>
              <p 
                css={{
                  color: "#3C763D",
                  fontSize:"18px", 
                  fontWeight:"bold"
                }}
              >
                Félicitations ! Vous êtes bien inscrit. 
              </p>
            </div>
          </div>
        }
          
        {/* NewsLetter - Form */}
        <div>
          {!loginProps.oauth && !openPopUpMessage &&
            <div css={newsLetterBackground}>
              <div 
                css={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                }}
              > 
                <p css={{margin: '0px', fontWeight: 'bold', fontSize: '18px'}}>Abonnez-vous à notre newsletter sur l'investissement citoyen</p>
                <div 
                  css={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    [mq[2]] : {
                      gap: '18px'
                    }
                  }}

                >
                  <InputField
                    data={form.mail}
                    placeholder="rene.coty@gmail.com"
                    onChange={handleInputChange}
                    inputCss={
                      {
                        '::placeholder' : {
                          color: '#989898',
                          fontSize: '16px',
                          fontStyle: 'italic',
                          opacity: '1'
                        },
                        '&:focus' : {
                          outline: 'none !important',
                        },
                        //fontSize: '20px',
                        marginBottom: '0px',
                        border: '0px',
                        padding: '10px 20px',
                        borderRadius : '4px 0 0 4px',
                        fontSize: '17px',
                        fontFamily:"raleway",
                        height:'100%',
                        [mq[2]] : {
                          width: '100%',
                          borderRadius: '4px'
                        }
                      }
                    }
                    validateField = {validateMandatory}
                    submitted={formSubmitted}
                  />
                  <SubmitButton 
                    label = "S'abonner" 
                    action={()=> publicService.inscriptionNewsLetter(body)}
                    backgroundColor="#13B9A1"
                    textColor="#000000"
                    style={btnStyles}  
                    onPreSubmit={() => {
                      setFormSubmitted(true)
                      setOpenPopUpMessage(true)
                    }} 
                    validateForm={isFormValid}
                  />
                </div>
              </div>
            </div> 
          }
          {loginProps.user && !loginProps.user?.IsMarketingMailsAllowed && !openPopUpMessage ?
            <div css={newsLetterBackground}>
              <div 
                css={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '20px'
                }}
              >
                <p css={{margin: '0px', fontWeight: 'bold', fontSize: '18px'}}>Abonnez-vous à notre newsletter sur l'investissement citoyen</p>
                <SubmitButton 
                  label = "Valider" 
                  action={()=> userService.inscriptionUserNewsLetter(loginProps?.oauth?.userId, loginProps?.oauth)}
                  onActionSuccess={onSubmitInfoSuccess}
                  onPreSubmit={() => {
                    setOpenPopUpMessage(true)
                  }} 
                  backgroundColor="#13B9A1"
                  textColor="#000000"
                  style={btnWithBorder} 
                />
              </div>
            </div>
          :
            <div></div>
          }
          <Container>
            <Grid  stackable={true}>
              <Grid.Row columns={4}>
                <Grid.Column width={5} rows={2} >
                    <Grid.Row>
                      <VillyLogo fill="white" width="75%"  />
                    </Grid.Row>
                  
                  <Grid.Row width={12} stretched>
                      <div css={{ 
                          marginLeft:"10px", 
                          fontSize:"0.875rem",
                          lineHeight : '1.25rem',
                          marginTop : "15px !important" 
                        }}>
                          <p> Villyz est une plateforme d'investissement à impact vous permettant d'engager votre épargne dans des collectivités, centres hospitaliers et sociétés qui font vivre nos territoires. Elle est agréée par l’Autorité des Marchés Financiers (AMF) sous le numéro FP-20221 et inscrite au RCS de Paris sous le numéro 884 826 520.</p>
                          <p> Investir présente un risque de perte en capital et de liquidité. N’investissez que l’argent dont vous n’avez pas besoin immédiatement et diversifiez votre épargne.</p>
                      </div>
                  </Grid.Row>
                  <div 
                    css={{
                      display : "flex", 
                      alignItems: "center", 
                      flexDirection: "row", 
                      marginTop:"10px"
                    }}>
                        <div css={{
                              marginInline : "8px",
                              height: "130px !important",
                              width: "130px !important"
                            }}>
                        <Image
                            size="small"
                            src="/img/colloc/PFP.png"
                            style={{objectFit:"contain"}}
                            alt="logo officiel de plate-forme de financement participatif agrée par l'état "
                          />
                        </div>
                          <Image
                            size="small"
                            src="/img/logos/Macaron_membre_FPF_2023.png"
                            alt="macaron de membre agréé financement participatif france 2023 "
                          />
                    </div>
                </Grid.Column>

                <Grid.Column width={7} stretched>
                  <Grid.Row width={13}>
                    <Grid>
                      <Grid.Column   width={8}>
                          <div css={{paddingLeft: "35px"}}>
                            <h3 css={{paddingTop : "10px"}} >A propos</h3>
                            <List role="navigation">
                              <List.Item as={NavLink} to="/partenaires" role="link" style={{color: footerLinkColor}}>
                                Partenaires
                              </List.Item>
                              <List.Item as={NavLink} to="/risques" role="link" style={{color: footerLinkColor}}>
                                Politique de risques
                              </List.Item>
                              <List.Item as={NavLink} to="/faq-page" role="link" style={{color: footerLinkColor}}>
                                F.A.Q 
                              </List.Item>
                              <List.Item as={NavLink} to="/statistiques" role="link" style={{color: footerLinkColor}}>
                                Statistiques et rapport d'activité
                              </List.Item>
                              <List.Item as={NavLink} to="/fiscalite" role="link" style={{color: footerLinkColor}}>
                                Fiscalité
                            </List.Item>
                            <List.Item as={NavLink} to="/reclamations" role="link" style={{color: footerLinkColor}}>
                              Réclamations
                            </List.Item>
                            </List>
                          </div>
                      </Grid.Column>
                  
                      <Grid.Column   width={8}>
                        <div css={{paddingLeft : "35px"}}>
                          <h3 css={{paddingTop : "10px"}}>Mentions légales</h3>
                          <List role="navigation">
                            <List.Item
                              as="a"
                              href="https://storage.gra.cloud.ovh.net/v1/AUTH_36f6e4b864bd4af78a6b90757af4090b/villyz_assets/cgu.pdf"
                              target="_blank"
                              rel="noreferer"
                              role="link"
                              style={{color: footerLinkColor}}                     
                            >
                              CGU Villyz
                            </List.Item>
                            <List.Item
                              as="a"
                              href="https://www.mangopay.com/terms/PSP/PSP_MANGOPAY_FR.pdf"
                              target="_blank"
                              rel="noreferer"
                              role="link"
                              style={{color: footerLinkColor}}    
                            >
                              CGU Mangopay
                            </List.Item>
                            <List.Item
                              as="a"
                              href="https://storage.gra.cloud.ovh.net/v1/AUTH_36f6e4b864bd4af78a6b90757af4090b/villyz_assets/rgbd.pdf"
                              target="_blank"
                              rel="noreferer"
                              role="link"
                              style={{color: footerLinkColor}}     
                            >Politique de confidentialité</List.Item>
                            <List.Item
                              as="a"
                              href="https://storage.gra.cloud.ovh.net/v1/AUTH_36f6e4b864bd4af78a6b90757af4090b/villyz_assets/ConflitsInterets.pdf"
                              target="_blank"
                              rel="noreferer"
                              role="link"
                              style={{color: footerLinkColor}}     
                            >Conflits d’intérêts</List.Item>
                            <List.Item as={NavLink} to="/mentions-legales" role="link" style={{color: footerLinkColor}}>
                              Mentions légales
                            </List.Item>
                          </List>
                        </div>
                    </Grid.Column>
                    </Grid>
                  </Grid.Row>

                  <div 
                    css={{
                      display : "flex",
                      flexBasis: "inherit",
                      justifyContent:"space-around", 
                      alignItems: "center", 
                      flexDirection: "row", 
                      marginTop:"25px", 
                      paddingInline : "95px"
                    }}>
                        <a href="https://www.facebook.com/Villyz.fr/" target="_blank" css={{ color: "#FFF", ":hover" : {color : "white"}}} aria-label="lien vers le facebook de Villyz">
                          <Icon  name="facebook f" centered size= "big" />
                        </a>
                        
                        <a href="https://twitter.com/villyz_fintech?lang=fr" target="_blank" css={{ color: "#FFF", ":hover" : {color : "white"}}} aria-label="lien vers le twitter de Villyz">
                        <Icon    name="twitter" centered size="big" />
                        </a>
                        
                        <a href="https://www.linkedin.com/company/65389432/" target="_blank" css={{ color: "#FFF", ":hover" : {color : "white"} }} aria-label="lien vers linkedin de Villyz">
                          <Icon   name="linkedin" centered size="big" />
                        </a>
                        
                        <a href="https://www.instagram.com/villyz.officiel/" target="_blank" css={{ color: "#FFF", ":hover" : {color : "white"} }} aria-label="lien vers l'instagram de Villyz">
                          <Icon   name="instagram" centered size="big" />
                        </a>
                  </div>
                </Grid.Column>
                
                <Grid.Column width={4} stretched >
                    <div 
                      css={{
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'space-between', 
                        paddingLeft: "18px"
                      }}>
                      <div css={{marginBottom: "20px"}}>
                        <h3 css={{paddingTop : "10px"}}>Contact</h3>
                        <div 
                          css={{
                            display: 'flex', 
                            flexDirection: 'row', 
                            textAlign : "center", 
                            marginBottom : "5px"
                          }}>
                          <div>
                            <Icon  name="mail outline" centered size="big" />
                            <a 
                              css={{
                                color : "rgba(255, 255, 255, 0.7)",
                                marginTop : "3px", ":hover" : {color : "white"}
                              }} 
                              href="mailto:contact@villyz.fr" 
                              target="_blank">contact@villyz.fr</a>
                          </div>
                        </div>
                        <div 
                          css={{
                            display: 'flex', 
                            flexDirection: 'row', 
                            textAlign : "center", 
                            marginBottom : "5px"
                          }}>
                          <Icon  name="calendar check outline" centered size="big" />
                          <a 
                            css={{
                              color : "rgba(255, 255, 255, 0.7)", 
                              marginTop : "4px", 
                              ":hover" : {
                                color : "white"
                              }
                            }} 
                            href="https://calendly.com/arthur-moraglia-villyz" 
                            target="_blank">
                              Prendre rendez-vous
                          </a>
                        </div>
                      </div>
                      <div 
                        css={{
                          display: 'block', 
                          textAlign: 'center', 
                          maxWidth : "200px", 
                          marginBottom: "16px"
                        }}>
                        <Image
                          size="medium"
                          src="/img/logos/powered-mangopayBlanc.png"
                          as="a"
                          href ="https://try.mangopay.com/paiement-mangopay-hub/"
                          target = "_blank"
                          css={{margin: 'auto'}}
                          alt="logo de mangopay"
                        />
                      </div>
                    </div>
                </Grid.Column>                     
              </Grid.Row>             
            </Grid>
          </Container>
        </div>
      </footer>
    </>
  );
}
