import React from 'react'
import { NavLink } from 'react-router-dom'
import { Table, Image, Grid } from 'semantic-ui-react'
import { TabCube } from "../generic/tab-cube"

export const citoyenPanelGeneral = [
    {
        key: '1',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Qu’est-ce qu’un investissement sur la plateforme Villyz ?</span>)},
        content: {
            content: (
              <div>
                <p>
                   Villyz permet aux citoyens d’investir dans les projets d’intérêt général des collectivités, établissements de santé, entreprises publiques locales ou de sociétés à actionnariat public.
                </p>
                <p>
                   Il vous permet de soutenir des projets tournés vers l’avenir qui comptent pour vous, de diversifier votre épargne avec un placement peu risqué et de lui conférer un fort impact local.
                </p>
                <p>
                   Il existe plusieurs instruments d’investissement : le prêt, l’obligation, les titres participatifs.
                </p>
              </div>
            ),
        }
    },
    {
        key: '2',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Qui peut proposer des projets sur la plateforme Villyz ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    La plateforme Villyz permet de financer les projets des communes, des établissements publics de coopération intercommunale (EPCI) à fiscalité propre, des départements et des régions.
                </p>
                <p>
                    Il est également possible de financer les projets d’autre porteurs de projets liés à l’intérêt général :
                        <ul>
                            <li>les établissements de santé : hôpitaux, centre de santé, EPHAD;</li>
                            <li>les entreprises publiques locales : Sociétés Publiques Locales (SPL) ou Sociétés d’Economie Mixte (SEM);</li>
                            <li>des sociétés privées lorsqu’un acteur public est présent à leur capital.</li>
                        </ul>
                </p>
                <p>
                    Chaque porteur de projet présente des caractéristiques propres et les risques des investissements peuvent varier, du moins risqué (collectivité et hôpitaux) au plus risqué (sociétés privées avec un actionnaire public). Pour plus d’informations, consultez notre politique des risques. 
                </p>
              </div>
            ),
        }
    },
    {
        key: '3',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Comment sont sélectionnés les projets proposés sur la plateforme  ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Les projets éligibles sont tous les projets, événements, activités, produits, services, causes qu’une collectivité ou une société à actionnariat public souhaite financer par une opération de finance participative définie par les lois et règlements en vigueur  (voir les  {"   "}
                    <a 
                    href="https://storage.gra.cloud.ovh.net/v1/AUTH_36f6e4b864bd4af78a6b90757af4090b/villyz_assets/cgu.pdf"
                    target="_blank"
                    >
                    CGU
                    </a>).
                </p>

                <p>
                   Nos équipes s’assurent de la solidité financière de chaque porteur de projet avant de mettre en ligne un projet
                   (voir notre <NavLink to={"/risques"}>politique de risques</NavLink>).
                </p>
              </div>
            ),
        }
    },
    {
        key: '4',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Qui peut investir ?</span>)},
        content: {
            content: (
                <div>
                   <p>
                      Presque tout le monde !
                    </p>
                    <p>
                      Toutes les personnes physiques de plus de 18 ans fiscalement domiciliés en France et valablement inscrits sur le site Villyz peuvent investir.
                    </p>
                </div>
            ),
        }
    },
    {
        key: '5',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Puis-je investir dans un projet d’un territoire dont je ne suis pas résident ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Pour la majorité des projets, il n’y a pas de restriction géographique ou de domiciliation. A titre d’exemple, un citoyen habitant à Lyon pourrait investir auprès de la ville de Brest et vice versa.
                </p>

                <p>
                    Dans le cas où un porteur de projet souhaiterait emprunter exclusivement auprès des citoyens de son territoire, cette spécificité sera affichée sur le projet et l’investissement sera rendu impossible pour les citoyens non-domiciliés dans ce territoire.
                </p>
              </div>
            ),
        }
    },
    {
        key: '6',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Combien puis-je investir ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Il est possible d’investir dès 1 € et jusqu'à un montant maximal fixé par le porteur de projet (ex : maximum de 5 000 € par investisseur).
                </p>

                <p>
                    Vous pouvez participer au financement de plusieurs projets d’un même porteur de projet ou de plusieurs porteurs de projet. Le paiement en ligne peut être réalisé par carte bancaire ou par virement bancaire.
                </p>

                <p>
                    Investir comporte des risques de perte totale ou partielle en capital et nécessite une immobilisation de son argent pendant la durée de l’investissement. Nous vous conseillons de bien répartir votre argent et de n’investir que l’argent dont vous n’avez pas besoin immédiatement.
                </p>
                <p>
                    Grâce à notre simulateur sur votre page profil, vous pouvez déterminer votre capacité de financement en fonction de vos revenus, charges et épargne. Les investissements que vous envisagez d’effectuer ne devraient pas représenter plus de 5% de votre patrimoine net total.
                </p>
              </div>
            ),
        }
    },
    {
        key: '7',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Que devient mon épargne lorsque j’ai financé un projet ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Une fois la campagne de financement terminée, les fonds sont transférés au porteur de projet afin qu’il puisse réaliser le projet présenté.
                </p>
                <p>
                    Vous êtes ensuite remboursé selon l’échéancier présenté dans le contrat de prêt du capital prêté et des intérêts.
                </p>
              </div>
            ),
        }
    },
    {
        key: '8',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Combien coûte le service Villyz pour les investisseurs  ?</span>)},
        content: {
            content: (
              <div>
                <p>Rien du tout!</p>
                <p>
                   Il n’y a aucun frais d’entrée pour les investisseurs, ni de gestion, ni de remboursement.
                </p>
                <p>
                   Pour assurer la vérification de l’identité des investisseurs, les paiements, l’évaluation du risque de la collectivité, la communication et le suivi du projet, Villyz se rémunère auprès des porteurs de projet sur le montant final de la collecte.
                </p>
              </div>
            ),
        }
    },
    {
        key: '9',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quelle utilisation est faite de mes données ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Lors de la création de votre profil, conformément à la réglementation, la plateforme récolte des données personnelles afin de vérifier votre identité et de fournir le meilleur service au niveau fonctionnel, tant pour l’identité des investisseurs que pour la réalisation des paiements.
                </p>

                <p>
                    La société Villyz, en tant que responsable de traitement s'engage à protéger la confidentialité et la sécurité des données personnelles en conformité avec la réglementation applicable à la protection des données à caractère personnel, et notamment le Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données (« RGPD »).
                </p>

                <p>
                    Pour plus d’informations, nous vous invitons à consulter notre {"   "}
                    <a
                    href="https://storage.gra.cloud.ovh.net/v1/AUTH_36f6e4b864bd4af78a6b90757af4090b/villyz_assets/rgbd.pdf"
                    target="_blank"
                    >
                    politique de confidentialité.
                    </a>
                </p>
              </div>
            ),
        }
    },
    {
        key: '10',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Comment puis-je m’inscrire  ?</span>)},
        content: {
            content: (
              <div>
                <p>
                L’inscription est simple et se fait en quelques clics, vous pouvez dès à présent vous munir de votre pièce d’identité (passeport ou carte nationale d’identité) et cliquer <NavLink to={"/login"}>ici</NavLink>. 
                </p>
              </div>
            ),
        }
    },
    {
        key: '11',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Qui puis-je contacter pour avoir plus d’informations  ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Pour toute question, nous sommes disponibles à l’adresse {"   "}
                    <a
                    href="mailto:contact@villyz.fr"
                    target="_blank"
                    >
                    contact@villyz.fr
                    </a>
                    .
                </p>
              </div>
            ),
        }
    },
]

export const collectivitePanelGeneral = [
    {
        key: '1',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Qu’est-ce que le financement citoyen proposé par Villyz ?</span>)},
        content: {
            content: (
              <div>
                    <p>
                    Villyz permet aux collectivités, établissements de santé, entreprises publiques locales ou à des sociétés privées avec un actionnariat public d’engager les citoyens grâce à un mode de financement innovant et démocratique  : l’investissement citoyen.
                    </p>
                    <p>
                    Il leur permet de se financer directement auprès des citoyens pour réaliser un ou plusieurs projets d’intérêt général. Les citoyens disposent quant à eux d’un moyen simple et transparent de placer leur épargne au service de leur territoire.
                    </p>
                    <p>
                    Lors d’une campagne de financement citoyen, la plateforme Villyz se charge d’identifier les investisseurs aux côtés du porteur de projet, de leur donner accès aux projets, ainsi que de réaliser leurs paiements et les remboursements automatiques.
                    </p>   
              </div>
            ),
        }
    },
    {
        key: '2',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quels porteurs de projet sont éligibles au financement citoyen ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Depuis le décret n° 2015-1670 du 14 décembre 2015, les communes, établissements publics de coopération intercommunale (EPCI) à fiscalité propre, départements et régions peuvent recourir au financement participatif.
                </p>
                <p>
                    Les établissements de santé, les entreprises publiques locales (ex : Sociétés Publiques Locales - SPL, Sociétés d’Economie Mixte - SEM), ainsi que certaines sociétés privées à actionnariat public sont éligibles au financement citoyen.
                </p>
              </div>
            ),
        }
    },
    {
        key: '3',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quels projets sont éligibles au financement citoyen ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Les projets éligibles sont tous les projets, événements, activités, produits, services, causes, qu’une collectivité ou une société à actionnariat public souhaite financer par une opération de finance participative définie par les Lois et règlements en vigueur  (voir les {"   "}
                    <a 
                    href="https://storage.gra.cloud.ovh.net/v1/AUTH_36f6e4b864bd4af78a6b90757af4090b/villyz_assets/cgu.pdf"
                    target="_blank"
                    >
                    CGU
                    </a>).
                </p>
                <p>
                   Seuls les projets de police et de maintien de l’ordre sont explicitement exclus des projets éligibles au financement.      
                </p>
              </div>
            ),
        }
    },
    {
        key: '4',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quelles sont les caractéristiques du financement citoyen  ?</span>)},
        content: {
            content: (
                <div>
                    <p>
                       Le financement citoyen permet de financer des projets jusqu’à 5 millions d’euros par an et par porteur de projet.
                    </p>
                    <p>
                        En réalisant un financement citoyen, les porteurs de projet ont le choix entre plusieurs instruments d’investissement : le prêt, l’obligation, les titres participatifs (uniquement pour les sociétés privées à actionnariat public).
                    </p>
                    <p>
                        Les campagnes de financement citoyen peuvent durer jusqu’à 3 mois.
                    </p>
                    <p>
                        Pendant toute cette période et jusqu’à ce que le montant maximal recherché soit atteint, chaque citoyen peut investir dès 1€ et jusqu’à une limite fixée par le porteur de projet (ex : 5 000€ maximum par investissement).
                    </p>
                </div>
            ),
        }
    },
    {
        key: '5',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Combien coûte le financement citoyen ?</span>)},
        content: {
            content: (
              <div>
                <p>
                   La commission pour réaliser un financement citoyen est facturée lors de la réception des fonds par le porteur de projet. Elle peut s’élever jusqu’à 6% H.T des fonds collectés.
                </p>
                <p>
                   La commission rémunère l’ensemble de la prestation de Villyz à toutes les étapes du projet (vérification de l’identité des investisseurs, paiements, évaluation du risque, accompagnement pour la communication et suivi du projet) : le paiement de la commission ne marque pas la fin de notre accompagnement !
                </p>
                <p>
                    Pour plus d’informations, consultez les {"   "}
                    <a 
                    href="https://storage.gra.cloud.ovh.net/v1/AUTH_36f6e4b864bd4af78a6b90757af4090b/villyz_assets/cgu.pdf"
                    target="_blank">
                    conditions générales d’utilisation.
                    </a>
                </p>
              </div>
            ),
        }
    },
    {
        key: '6',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Que se passe-t-il après une campagne de financement citoyen ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Les équipes de Villyz accompagnent chaque porteur de projet jusqu’à la fin du remboursement pour maintenir la dynamique citoyenne autour des projets financés. 
                </p>
                <p>
                    Chaque porteur de projet dispose ainsi de la possibilité de poursuivre le dialogue avec les citoyens ayant soutenu le projet financé, en les informant ou les fédérant autour d’évènements.
                </p>
              </div>
            ),
        }
    },
    {
        key: '7',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Est-il possible de réaliser plusieurs demandes de financement citoyen sur la plateforme  ?</span>)},
        content: {
            content: (
              <div>
                <p>
                   Oui, chaque porteur de projet peut effectuer plusieurs demandes de financement pour des projets différents dans la limite de 5 million d’euros au total par an.
                </p>
              </div>
            ),
        }
    },
    {
        key: '8',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>A-t-on connaissance de l’identité des investisseurs ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Le contrat signé par le porteur de projet recense l’ensemble des investisseurs (nom, prénom, lieu et date de naissance, adresse postale et courriel), tandis que les investisseur ne peuvent pas voir l’identité des autres investisseurs.
                </p>
                <p>
                    Villyz s’engage à respecter la sécurité et la confidentialité des données en conformité avec la réglementation applicable à la protection des données à caractère personnel, et notamment le Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données (« RGPD »).
                </p>

                <p>Pour plus d’informations, consultez notre {"   "}
                    <a
                    href="https://storage.gra.cloud.ovh.net/v1/AUTH_36f6e4b864bd4af78a6b90757af4090b/villyz_assets/Politique_de_confidentialite.pdf"
                    target="_blank"
                    >
                    politique de confidentialité.
                    </a>
                </p>
              </div>
            ),
        }
    },
    {
        key: '9',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Peut-on restreindre le financement citoyen à un territoire ?</span>)},
        content: {
            content: (
              <div>
                <p>
                   Bien que les projets à financer soient par nature ouverts à l’ensemble des citoyens, restreindre le financement d’un projet à un territoire donné est possible sur demande auprès de nos équipes.
                </p>
              </div>
            ),
        }
    },
    {
        key: '10',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quelle est la différence avec du don ou du mécénat ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Lorsque les particuliers investissent auprès d’un porteur de projet, ce dernier rembourse capital et intérêts aux investisseurs, ce qui n’est pas le cas pour le don ou le mécénat.
                </p>
                <p>
                    Par ailleurs, l’investissement citoyen s’adresse à toutes les personnes souhaitant donner du sens à leur épargne, sans condition de revenus, il peut ainsi être considéré comme plus inclusif que du don ou mécénat.
                </p>
                <p>
                    Soutenir son territoire devient un privilège réservé à tous.
                </p>
              </div>
            ),
        }
    },
    {
        key: '11',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Pourquoi impliquer les contribuables qui paient déjà des impôts pour financer des projets publics?</span>)},
        content: {
            content: (
              <div>
                <p>
                    D’après l’étude menée par Villyz et Créatests en septembre 2020 auprès de 1045 personnes, 83% des Français se disent prêts à prêter à leur collectivité.
                </p>
                <p>
                    L’investissement citoyen répond à une demande croissante des citoyens qui souhaitent être davantage inclus dans les politiques publiques locales tout en valorisant leur épargne dans des projets à impact.
                </p>
              </div>
            ),
        }
    },
    {
        key: '12',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Comment puis-je inscrire mon organisation sur la plateforme ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    L’inscription est très simple et se fait en quelques clics.
                </p>
                <p>
                    Pour une collectivité, il suffit au représentant légal de munir de sa pièce d’identité, de l’avis de situation SIRENE de la collectivité et du procès-verbal de son élection en tant que représentant légal puis de se rendre sur la <NavLink to = "/login">page d’inscription</NavLink>.
                </p>
                <p>
                    Pour un établissement de santé, une entreprise publique locale ou une société privée, il faudra se munir d’un K-bis de moins de trois mois et de la pièce d’identité du dirigeant.
                </p>
              </div>
            ),
        }
    },
    {
        key: '13',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Qui puis-je contacter pour avoir plus d’informations ?</span>)},
        content: {
            content: (
              <div>
                <p>
                   Pour toute question, nous sommes disponibles à l’adresse {"   "}
                    <a 
                    href="mailto:collectivites@villyz.fr"
                    target="_blank"
                    >
                    collectivites@villyz.fr
                    </a>  
                </p>
              </div>
            ),
        }
    },
]

export const citoyenPanelPretParticipatifCitoyen = [
    {
        key: '1',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quelles sont les caractéristiques financières de l’investissement citoyen proposé sur Villyz ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Sur la page de présentation du projet, vous trouverez les caractéristiques de l'investissement (ex : instrument d’investissement, montant, taux d’intérêt, durée), les modalités de remboursement (annuel, semestriel, trimestriel ou mensuel) choisies par le porteur de projet ainsi que le type d’amortissement :
                </p>
                <ul>
                    <li>Echéance constante (le montant des échéances, soit le capital remboursé plus les intérêts, est fixe)</li>
                    <li>Amortissement constant (le capital remboursé chaque mois est fixe)</li>
                    <li>In fine (l’intégralité du capital est remboursée à la fin du de l’investissement)</li>
                </ul>
                <p>
                    Les investissements proposés sur la plateforme ont une durée minimale d’un an et leur  remboursement ne peut être demandé par anticipation par les investisseurs.
                </p>
              </div>
            ),
        }
    },
    {
        key: '2',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Pourquoi financer les projets de ma collectivité, mon hôpital ou d’une société à actionnariat public alors que je paie déjà des impôts ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Si les impôts constituent un devoir civique, l'investissement citoyen est un nouvel outil démocratique pour donner un pouvoir supplémentaire à chaque citoyen, grâce à son épargne.
                </p>

                <p>
                    Contrairement aux impôts, vous pouvez choisir d’investir directement aux projets qui vous tiennent à cœur. Aux échéances, vous êtes remboursés, avec les intérêts, du montant investi. C’est ainsi l’occasion de valoriser votre épargne dans des projets locaux et à impact, partout en France.
                </p>
              </div>
            ),
        }
    },
    {
        key: '3',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quelle est la différence avec du don ou du mécénat ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Lorsque les particuliers investissent dans un projet d’intérêt général, le porteur de projet rembourse le capital et verse en plus des intérêts aux citoyens, ce qui n’est pas le cas pour le don ou le mécénat.
                </p>
                <p>
                    Par ailleurs, l’investissement citoyen s’adresse à tous les personnes souhaitant donner du sens à leur épargne, sans condition de revenus, il peut ainsi être considéré comme plus inclusif que du don ou mécénat.
                </p>
                <p>
                    Soutenir son territoire devient un privilège réservé à tous.
                </p>
              </div>
            ),
        }
    },
    {
        key: '4',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Puis-je céder mon investissement à un tiers ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Tout dépend de l’instrument d’investissement !
                </p>
                <p>
                    Les prêts participatifs sont incessibles, tandis que les obligations, titres participatifs et parts sociales peuvent être négociées dans les conditions du contrat et de la réglementation en vigueur. 
                </p>
                <p>
                    Pour toute information, contactez-nous à {"   "}
                    <a 
                    href="mailto:collectivites@villyz.fr"
                    target="_blank"
                    >
                    contact@villyz.fr
                    </a>  
                    .
                </p>
              </div>
            ),
        }
    },
    {
        key: '5',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Existe-t-il un droit de rétractation à ma promesse d'investissement ?</span>)},
        content: {
            content: (
              <div>
                <p>
                   Une fois votre paiement effectué sur la plateforme et le contrat d’investissement signé, vous avez 4 jours pour demander l’annulation de votre investissement et son remboursement. 
                </p>
                <p>
                   Pendant toute cette période, vous pouvez vous rétracter en toute simplicité sur votre tableau de bord.
                </p>
              </div>
            ),
        }
    },
    {
        key: '6',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Un porteur de projet peut rembourser par anticipation ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Une fois que les fonds collectés pour un projet sont débloqués, le porteur de projet s’engage à rembourser les investisseurs selon les termes du contrat et ne peut pas effectuer de remboursement par anticipation.
                </p>
              </div>
            ),
        }
    }
]

export const collectivitePanelPublicationDesProjetsSurLaPlateformeEtDéroulementDesCampagnesDeFinancementCitoyen = [
    {
        key: '1',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Le financement citoyen nécessite-t-il une procédure d’appel d’offres  ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    La procédure d’appel d’offres pour les entités publiques n’est requise que si le prix du service de Villyz est supérieur à 40 000 euros.
                </p>
                <p>
                    Dans le cas d’une convention portant sur un prix de service dépassant cette somme, une mise en concurrence dépendra du Code de la Commande Publique (CCP) et pourra être préalablement soumise à une procédure d’appel d’offre.
                </p>
              </div>
            ),
        }
    },
    {
        key: '2',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Combien de temps les projets envoyés sur la plateforme Villyz mettent-ils pour être validés  ?</span>)},
        content: {
            content: (
              <div>
                <p>
                   Une fois les informations du projet renseignées et la demande de publication envoyée, celle-ci sera étudiée par le comité de sélection de Villyz, qui répondra dans les 72 heures suivant sa réception.
                </p>
              </div>
            ),
        }
    },
    {
        key: '3',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quels sont les indicateurs utilisés pour évaluer la solidité financière d'un porteur de projet ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Le comité de sélection de Villyz analyse la solidité financière du porteur de projet ainsi que l’impact financier du projet sur ses finances afin de s'assurer que tout projet éligible puisse être présenté au financement des citoyens.
                </p>
                <p>
                    Dans le cas d’une collectivité, plusieurs critères financiers sont analysés, notamment :
                </p>
                <ul>
                    <li>
                        La capacité de désendettement 
                    </li>

                    <li>
                        La marge d’autofinancement courante
                    </li>

                    <li>
                        Le coefficient de rigidité structurelle
                    </li>

                    <li>
                        Le taux d’épargne brute
                    </li>

                    <li>
                        L'encours total de dette
                    </li>
                </ul>
                <p>
                    Dans le cas d’un établissement de santé, d’une entreprise publique locale ou d’une société à actionnariat public, plusieurs critères financiers sont analysés, notamment :
                </p>
                <ul>
                    <li>
                       Le fonds de roulement net à court terme
                    </li>

                    <li>
                       La part des réserves dans l’actif 
                    </li>

                    <li>
                        Le rapport entre les bénéfices avant intérêts et impôt et l’actif 
                    </li>

                    <li>
                        Le ratio de levier
                    </li>
                    <li>
                        Le taux de rotation des actifs
                    </li>
                </ul>
                <p>
                    Après évaluation de la solidité financière du porteur de projet et de l'impact financier du projet proposé, Villyz s'engage à fournir à la collectivité une réponse dans les 72 heures suivant la demande de publication de projet.
                </p>
                <p>
                    Une note d'analyse de la collectivité synthétisant la situation financière du porteur de projet sera publiée sur la page du projet pour permettre aux citoyens d'avoir accès à ces informationss (voir notre <NavLink to="/risques">politique de risques</NavLink>).
                </p>
              </div>
            ),
        }
    },
    {
        key: '4',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Une fois un projet est publié, quelles sont les informations visibles sur la plateforme ?</span>)},
        content: {
            content: (
              <div>
                <p>
                   Une fois accepté par le comité de sélection, le porteur du projet peut publier directement son projet.
                </p>
                <p>
                   Les investisseurs auront accès à une présentation du porteur de projet, à la description du projet, aux caractéristiques de l’investissement (instrument, montant demandé, taux d’intérêt et maturité), au plan d’affaires, à l’analyse du comité de sélection de Villyz et les sources de financement complémentaires du projet (ex : subventions, autofinancement ou crédit bancaire).
                </p>
              </div>
            ),
        }
    },
    {
        key: '5',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Que se passe-t-il si le montant collecté à la fin de la campagne n’atteint pas le montant demandé ou le dépasse  ?</span>)},
        content: {
            content: (
              <div>
                <p>
                  Tout dépend du montant atteint à l’issue de la campagne de financement :
                </p>

                <Table celled padded>
                    <Table.Header>
                        <Table.Row>
                        <Table.HeaderCell>Montant collecté</Table.HeaderCell>
                        <Table.HeaderCell>Conséquences</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                        <Table.Cell>
                            Supérieur ou égal à 80% de l’objectif
                        </Table.Cell>
                        <Table.Cell>
                            Le porteur de projet acceptera les fonds pour réaliser le projet
                        </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                        <Table.Cell>
                            Inférieur à 80% de l’objectif
                        </Table.Cell>
                        <Table.Cell>
                            Le porteur de projet pourra décider soit d’accepter les fonds soit de rembourser les investisseurs.
                        </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>

                <p>
                   A l’approche de la fin de la collecte, le porteur de projet a la possibilité de prolonger la campagne de financement.
                </p>
              </div>
            ),
        }
    }
]

export const citoyenPanelRisques = [
    {
        key: '1',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quels sont les risques de d’investir auprès d’un porteur de projet sur la plateforme Villyz ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Le comité de sélection de Villyz analyse la solidité financière de chaque porteur de projet, collectivité ou société à actionnariat public, ainsi que l’impact financier du projet sur ses finances afin de s'assurer que tout projet éligible puisse être présenté au financement des citoyens.
                </p>
                <p>
                    Pour autant, l’investissement citoyen présente un risque de perte en capital car, comme toute personne morale, une collectivité, un établissement de santé, une entreprise publique locale ou une société à actionnariat public peut faire défaut ou avoir du retard dans ses remboursements. Nous vous conseillons de diversifier vos placements pour minimiser le risque.
                </p>

                <p>
                    Avant d’investir, prenez en compte la durée de votre investissement, le profil de risque renseigné ainsi que le montant. Les sommes investies sont indisponibles pendant toute la durée de l’investissement.
                </p>

                <p>
                    Grâce à notre simulateur sur votre page profil, vous pouvez déterminer votre capacité de financement en fonction de votre revenu et de vos charges. Les investissements que vous envisagez d'octroyer ne doivent pas représenter plus de 10% de votre patrimoine total. 
                </p>
              </div>
            ),
        }
    },
    {
        key: '2',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Mon investissement est-il garanti ?</span>)},
        content: {
            content: (
              <div>
                <p>
                   Bien que le taux de défaut des porteurs de projets de la plateforme soit faibles car leurs finances suivent des règles strictes, les investissements ne  sont pas garantis. Il existe ainsi un risque de non-remboursement du capital à prendre en compte avant d’investir. 
                </p>
              </div>
            ),
        }
    },
    {
        key: '3',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Que se passe-t-il si le montant collecté à la fin de la campagne n’atteint pas le montant demandé ou le dépasse ?</span>)},
        content: {
            content: (
              <div>
                <p>
                   Tout dépend du montant atteint à l’issue de la campagne de financement :
                </p>
                <Table celled padded>
                    <Table.Header>
                        <Table.Row>
                        <Table.HeaderCell>Montant collecté</Table.HeaderCell>
                        <Table.HeaderCell>Conséquences</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                        <Table.Cell>
                            Supérieur ou égal à 80% de l’objectif
                        </Table.Cell>
                        <Table.Cell>
                            Le porteur de projet acceptera les fonds pour réaliser le projet.
                        </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                        <Table.Cell>
                            Inférieur à 80% de l’objectif
                        </Table.Cell>
                        <Table.Cell>
                            Le porteur de projet pourra décider soit d’accepter les fonds soit de rembourser les investisseurs.
                        </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>

                <p>
                    A l’approche de la fin de la collecte, le porteur de projet a la possibilité de prolonger la campagne de financement. 
                </p>
              </div>
            ),
        }
    },
    {
        key: '4',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Que se passe-t-il si un porteur de projet ne rembourse pas une échéance ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Si une échéance est impayée, nous vous en informerons immédiatement. Nous prenons contact avec le porteur de projet afin d’analyser la situation. En cas d’erreur technique, nous tâcherons de rétablir au plus vite le prélèvement.
                </p>

                <p>
                    Si l’impayé persiste malgré nos relances dans les 2 jours calendaires suivant la date de l’échéance impayée, des relances seront effectuées, puis une mise en demeure 8 jours calendaires après la date de l’échéance impayée. 
                </p>
              </div>
            ),
        }
    },
    {
        key: '5',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Que se passe-t-il si la plateforme cesse son activité  ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Villyz a conclu un contrat de gestion extinctive avec un partenaire afin de permettre à la plateforme de continuer les remboursements jusqu’à la clôture du dernier financement citoyen.
                </p>

                <p>
                    En cas de défaillance de Villyz, les remboursements auprès des investisseurs sont ainsi assurés.
                </p>
              </div>
            ),
        }
    }
]

export const collectivitePanelRetraitDesFondsCollectésEtRemboursements = [
    {
        key: '1',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Une fois la campagne de financement citoyen réalisée, comment se déroule la mise à disposition des fonds ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Les sommes investies par les citoyens sont conservées sur un compte séquestre sécurisé par notre partenaire de paiement Mangopay.
                </p>
                <p>
                    Une fois la campagne de financement réussie, les porteurs de projet peuvent demander le déblocage des fonds qui seront versés dans les 5 jours calendaires sur leur compte bancaire.
                </p>
              </div>
            ),
        }
    },
    {
        key: '2',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Comment se déroulent les remboursements  ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Ils sont au choix du porteur de projet : annuels, semestriels, trimestriels ou mensuels, synthétisés dans un tableau d’amortissement accessible à tout moment depuis leur tableau de bord.
                </p>

                <p>
                    Les remboursements sont réalisés par mandat de prélèvement SEPA, signé avant le déblocage des fonds. Lors de chaque échéance, Villyz se chargera de rembourser chaque investisseur d’une manière automatisée.
                </p>
              </div>
            ),
        }
    },
    {
        key: '3',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Est-il possible de rembourser mon emprunt par anticipation  ?</span>)},
        content: {
            content: (
              <div>
                <p>
                   Il est impossible pour les porteurs de projet de demander un remboursement par anticipation d’un emprunt en cours, les échéances de remboursements définies dans le contrat d’investissement doivent être respectées.
                </p>
              </div>
            ),
        }
    },
    {
        key: '4',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Que se passe-t-il si la plateforme cesse son activité  ?</span>)},
        content: {
            content: (
              <div>
                <p>
                   Villyz a conclu un contrat de gestion extinctive avec un partenaire afin de permettre à la plateforme de continuer les remboursements jusqu’au remboursement total du financement citoyen. 
                </p>

                <p>
                   En cas de défaillance, les remboursements auprès des investisseurs sont ainsi assurés.
                </p>
              </div>
            ),
        }
    }
]

export const citoyenPanelFiscalité = [
    {
        key: '1',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quelle est la fiscalité de l’investissement citoyen</span>)},
        content: {
            content: (
              <div>
                <p>
                    Selon le régime fiscal français, la plateforme Villyz prélève automatiquement sur les intérêts de vos investissements une retenue à la source forfaitaire de 30 % au titre du prélèvement forfaitaire unique.
                </p>

                <p>
                    Il s’agit d’un acompte sur votre impôt sur le revenu (12,8 %), auquel s'ajoutent les prélèvements sociaux (17,2 %). 
                </p>

                <p>
                    Exemple d’un prêt participatif avec échéance constante :
                    <ul>
                        <li>Montant du prêt: 2 000€</li>
                        <li>Taux d’intérêt: 3%</li>
                        <li>Maturité: 2 ans</li>
                        <li>Périodicité: mensuelle. Le prélèvement est effectué à chaque mensualité sur les intérêts perçus à chaque échéance.</li>
                    </ul>
                </p>

                <p>
                    Pour la première année, le prélèvement sera de 5,94€ au titre de l’acompte sur l’impôt sur le revenu et 7,98€ au titre des prélèvements sociaux (CSG, CRDS) au total. Le portefeuille électronique de l’investisseur recevra 32,4€ d’intérêts au titre de la première année.
                </p>

                <p>
                    Dans le cas d’investissement en titres participatifs ou en parts sociales, vous avez la possibilité de déduire votre investissement de votre impôt sur le revenu à hauteur de 25%, en vertu du dispositif IR-PME de la loi Madelin.
                </p>

                <p>
                    Cette réduction s’appliquera à l’imposition de l’année suivant votre investissement et nécessite de conserver vos titres au moins jusqu’au 31 décembre de la cinquième année de votre souscription. 
                </p>

                <p>
                    Le maximum de cette exonération est de 50 000€ pour les contribuables célibataires, veufs ou divorcés et de 100 000€ pour les contribuables mariés, liés par un Pacte Civil de Solidarité (PACS) ou vivant en concubinage et soumis à une imposition commune.
                </p>

                <p>
                    Il est à noter que la fraction des investissements excédant les plafonds annuels permet une réduction d'impôt pendant les 4 années suivantes, dans les mêmes conditions.
                </p>
              </div>
            ),
        }
    },
    {
        key: '2',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Comment déclarer les revenus issus des investissements effectués sur la plateforme Villyz aux impôts ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Pour aider à déclarer les revenus provenant des investissements effectués via la plateforme, Villyz met à disposition un Imprimé fiscal unique avant le 15 avril suivant la fin de l’année fiscale correspondante.​
                </p>

                <p>
                    Par ailleurs, les citoyens ont la possibilité d’imputer une éventuelle perte en capital subie, en cas de non-remboursement d’un investissement, des intérêts perçus dans le calcul de l’impôt sur le revenu.​
                </p>
              </div>
            ),
        }
    },
    {
        key: '3',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Peut-on être dispensé du prélèvement forfaitaire unique ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Les investisseurs présentant un revenu fiscal de référence inférieur à 25 000€ pour les célibataires, divorcés ou veufs, ou inférieur à 50 000€ en cas d’imposition commune, peuvent demander à être dispensés du paiement de l’acompte de l’impôt sur le revenu.
                </p>
              </div>
            ),
        }
    }
]